

import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../Components/Dashboard'
import MyProfile from '../Components/MyProfile'
import SignUp from '../Components/LoginAuth/SignUp'
import SignIn from '../Components/LoginAuth/SignIn'
import ForgotPassword from '../Components/LoginAuth/ForgotPassword'
import OTPVerification from '../Components/LoginAuth/OTPVarification'
import NewPassword from '../Components/LoginAuth/NewPassword'
import PublicLayout from '../Layouts/PublicLayout'
import PrivateLayout from '../Layouts/PrivateLayout'
import { CashfuseCustomer } from '../Components/CashfuseCustomer';
import { MyTransection } from '../Components/MyTransection';
import { KycComponents } from '../Components/KycComponents';
import { Wallet } from '../Components/Wallet';
import CashfuseOffer from '../Components/CashfuseOffer';
import Addoffer from '../Components/Cashfuseoffer/Addoffer';
import Home from '../Components/LandingPage/Home'
import AboutUs from '../Components/LandingPage/AboutUs/AboutUs';
import OfferPage from '../Components/LandingPage/offerPage/OfferPage';
import Layout from './Layout';
import ContactUs from '../Components/LandingPage/ContactUs/ContactUs';
import Faq from '../Components/LandingPage/Faq';
import { Toastify } from '../Components/Common/Toastify';
import TermsConditions from '../Components/TermsConditions';
import { Help } from '../Components/Help';

import CreateBanner from '../Components/CreateBanner';
import ShopDetail from '../Components/LandingPage/offerPage/ShopDetail';

const AppRoutes = ({ onProfileUpdate, profileImage, profileName }) => {



  return (
    <Routes>

      <Route element={<PublicLayout />}>




        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/about" element={<Layout><AboutUs /></Layout>} />
        <Route path="/offer" element={<Layout><OfferPage /></Layout>} />
        <Route path="/contactus" element={<Layout><ContactUs /></Layout>} />
        <Route path="/faq" element={<Layout><Faq /></Layout>} />
        <Route path="/shopdetail" element={<Layout><ShopDetail/></Layout>} />
        <Route path='/offer' element={<OfferPage />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/signin' element={<SignIn />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/otpvarification' element={<OTPVerification />} />
        <Route path='/newpassword' element={<NewPassword />} />


      </Route>

      <Route element={<PrivateLayout onProfileUpdate={onProfileUpdate} profileImage={profileImage} profileName={profileName} />}>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/myprofile' element={<MyProfile onProfileUpdate={onProfileUpdate} />} />
        <Route path='/cashfuseCustomer' element={<CashfuseCustomer />} />
        <Route path='/myTransection' element={<MyTransection />} />
        <Route path='/kycComponents' element={<KycComponents />} />
        <Route path='/wallet' element={<Wallet />} />
        <Route path='/cashFuseOffer' element={<CashfuseOffer />} />
        <Route path='/createbanner' element={<CreateBanner />} />
        <Route path='/addOffer' element={<Addoffer />} />
        <Route path='/conditions' element={<TermsConditions />} />
        <Route path='/help' element={<Help />} />
       




      </Route>
    </Routes>



  )
}

export default AppRoutes
