import React, { useEffect, useState } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo/LOGO.png";
import { LuSearch } from "react-icons/lu";
import { ClearLocalData } from "../Networking/localStorageHelper";
import UserDefaultImage from "../assets/images/UserDefultImage.png";

const Header = ({ profileImage, profileName, profileData }) => {
  // const BASE_URL = "https://liveuat.com:5438/";
  const BASE_URL = "https://liveuat.com:5438";
  console.log("Header props:", { profileImage, profileName });
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const localimage = localStorage.getItem("profileImage");
  const localName = localStorage.getItem("profileName");
  const userName = localName || "Guest";
  // const userImage = localimage
  //   ? typeof localimage === "object"
  //     ? URL.createObjectURL(localimage)
  //     : `${BASE_URL}${localimage}`
  //   : UserDefaultImage;
  // console.log(userImage, "userImage11111....");
  const userImage = localimage
    ? typeof localimage === "object"
      ? URL.createObjectURL(localimage)
      : `${BASE_URL}/${localimage}`
    : UserDefaultImage;
  const handleLogout = () => {
    let windowConfirm = window.confirm("Are you sure you want to log out");
    if (!windowConfirm) {
      return;
    }
    ClearLocalData("");
    navigate("/signin");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Disclosure as="nav" className={`bg-white shadow-md  ${isSticky ? "sticky top-0 z-50" : ""}`}>
        <div className="mx-auto max-w-8xl px-2 sm:px-6 lg:px-12">
          <div className="relative flex h-16  items-center justify-between">
            <div className="absolute inset-y-0  left-28 flex items-center sm:hidden">
              {/* Mobile menu button */}

              <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2    ">
                <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
              </DisclosureButton>
            </div>
            <div className="flex flex-1 items-center   sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <Link to="/">
                  <img src={logo} alt="Logo" />
                </Link>
              </div>
              <div className="hidden sm:ml-6 sm:block ps-20">
                <div className="flex space-x-20 drop  sm:bg-white sm:z-2   sm:relative">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search..."
                      className=" w-100 xl:w-96 sm:w-100 lg:w-96 md:w-56 rounded-md px-3 py-2 text-sm text-gray-900 bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200"
                    />
                    <div className="absolute inset-y-0 right-3 flex items-center pl-3 pointer-events-none">
                      <LuSearch className="text-gray-500" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="xl:pe-4  lg:pe-4 md:pe-4 sm:pe-2">
                <BellIcon aria-hidden="true" className="h-6 w-6" />
              </div>

              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <MenuButton className="relative  rounded-full  flex align-center text-sm      ">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <img alt="Profile" src={userImage} className="h-8 w-8 rounded-full" />
                    {/* <h5 className="pt-1.5 ps-3">{userName}</h5> */}
                    <div className="xl:block lg:block md:block sm:block px-4 py-2 text-sm text-gray-700  hidden">
                      <h5 className="pt-1.5 ps-3">{userName}</h5>
                    </div>
                  </MenuButton>
                </div>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <MenuItem>
                    <Link href="/myprofile" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                      Your Profile
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                      Settings
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <Link className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">Sign out</Link>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </div>
        </div>

        <DisclosurePanel className="sm:hidden">
          <div className="space-y-1 px-2 pb-3 pt-2">
            {/* Mobile search bar */}
            <input
              type="text"
              placeholder="Search..."
              className="block w-full rounded-md px-3 py-2 text-base text-gray-900 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            />
          </div>
        </DisclosurePanel>
      </Disclosure>
    </>
  );
};

export default Header;
