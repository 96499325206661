import React from 'react'
import ShopImg1 from '../../../assets/images/Offers/shopimg1.png'
import ShopImg2 from '../../../assets/images/Offers/shopimg2.png'
import ShopImg3 from '../../../assets/images/Offers/shopimg3.png'
import { FaStar } from "react-icons/fa6";
import { FaStarHalfAlt } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
// import { FaPhoneAlt } from "react-icons/fa";
// import { CiShare2 } from "react-icons/ci";
import CashFuseCard from '../../Common/Cards/CahsFuseCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import OfferImg1 from '../../../assets/images/home/offer1.png';
import OfferImg2 from '../../../assets/images/home/offer22.png';
import OfferImg3 from '../../../assets/images/home/offer33.png';
// import useNetworkServices from "../../../Networking/baseAPIManager";
import { useLocation } from 'react-router-dom';
const ShopDetail = () => {
    const location = useLocation();
    const shopName = location.state?.shopName || "Unknown Shop";
    const workingHour = location.state?.workingHour || "Unavailable";
    // const PhNumber = location.state?.PhNumber || "Unavailable";
    const Address = location.state?.Address || "Unavailable";
    const Terms = location.state?.Terms || "Unavailable";
    const merchantDetails = Array.isArray(location.state?.merchantDetails) ? location.state.merchantDetails : [];
    const storeOffer = location.state?.storeOffer || "Offer not available";
    console.log(merchantDetails, "TopOfferData+++")

    // const { GetTopOffer } = useNetworkServices()
    // const [TopOfferData, setTopOfferData] = useState([]);



    // const fetchOfferData = async () => {
    //     try {
    //         const response = await GetTopOffer();
    //         setTopOfferData(response.data.data.offers || []); // Ensure it's an array
    //         console.log(response.data.data.offers, "response==>111");
    //     } catch (error) {
    //         console.error("Error Fetching Offer Data", error);
    //     }
    // };

    // useEffect(() => {
    //     fetchOfferData();
    // }, [GetTopOffer]);



    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const offerImages = [OfferImg1, OfferImg2, OfferImg3];

    return (
        <>

            <section className='py-20'>
                <div className='container m-auto '>
                    <div className="grid grid-cols-12 gap-4">
                        <div className='col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-5'>
                            <img src={ShopImg1} alt="" className='w-full h-full  ' />
                        </div>
                        <div className='col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-5'>
                            <img src={ShopImg2} alt="" className='w-full  h-full' />
                        </div>
                        <div className='col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-2 sm:flex  md:flex xl:flex-wrap lg:flex gap-2'>
                            <img src={ShopImg3} alt="" className='w-full' />
                            <img src={ShopImg3} alt="" className='w-full ' />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container m-auto pb-20">
                    <div className="grid grid-cols-12 gap-x-4">
                        <div class="col-span-12 xl:flex lg:flex md:flex sm:flex justify-between">
                            <div className="col-span-6">
                                <h2 className='font-bold text-custom-blue lg:text-[40px] text-[35px] md:text-[45px]  animate-fadeInLeft opacity-100'

                                    style={{ animationDelay: '0s' }}> {shopName}</h2>
                                {/* <h6 className='text-gray-400 text-lg'>Vision Alta Men’s Shoes</h6> */}
                                <span className='flex text-[#EDB310] items-center pt-2'><FaStar />  <FaStar /> <FaStar /> <FaStar /> <FaStarHalfAlt /> <span className='ps-3'>4.8 </span></span>
                                <p className='text-gray-400 text-lg pt-2'> {Address}  </p>
                                <div className='flex gap-3 pt-3'>

                                    <span className='flex items-center text-sm text-gray-400 border-[.5px] border-gray p-1'><IoLocationOutline className='text-custom-blue me-2' /> 2.5 km</span>
                                    {/* <span className='flex items-center text-sm text-gray-400 border-[.5px] border-gray p-1'><FaPhoneAlt className='text-custom-blue me-2 ' />  {PhNumber}</span> */}
                                    {/* <span className='flex items-center text-sm text-gray-400 border-[.5px] border-gray p-1'><CiShare2 className='text-custom-blue me-2' /> Share</span> */}
                                </div>
                            </div>
                            <div className="col-span-6">
                                <p className="mt-2 mb-5 text-gray-400 text-[23px]">
                                    Shop  Time
                                    <span className="text-[#E73921] font-semibold"> {workingHour} </span>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <div className="container m-auto xl:gap-6 lg:gap-6 items-center pb-20 ">

                {storeOffer.length > 0 ? (
                    <Slider {...settings}>
                        {storeOffer.map((merchant, index) => (
                            <div key={merchant.merchantId?._id || index} className="p-2">
                                <CashFuseCard
                                    shopName={merchant?.merchantId?.businessDetails?.shopName || "Shop name not available"}
                                    offerName={merchant?.offerTitle || "Offer Title"}
                                    offerAmount={merchant?.storeOffer || "Offer Amount"}
                                    backgroundImage={offerImages[index % offerImages.length]}
                                />

                                
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <p>No offers available at the moment.</p>
                )}
            </div>


            <section>
                <div className="container m-auto pb-20 pt-10">
                    <div className="grid grid-cols-12 gap-3">
                        <div className="col-span-6">
                            <h2 className='font-bold text-custom-blue lg:text-[40px] text-[35px] md:text-[45px]'>About Us </h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad laboriosam soluta aliquid magnam cupiditate quibusdam sequi consequuntur quaerat labore doloribus. Tenetur ratione sint quod rerum cupiditate. Nam ipsam tempora id?</p>
                        </div>
                        <div className="col-span-6">
                            <h2 className='font-bold text-custom-blue lg:text-[40px] text-[35px] md:text-[45px]'>Terms & Conditions  </h2>
                            <p> {Terms}</p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ShopDetail;
