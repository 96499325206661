import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SubHeaderMenu from "./Sidebar/SubHeaderMenu";
import TabsSecond from "./Common/TabsSecond";
import PersonalInfoKyc from "./KYC/PersonalInfoKyc";
import MyBusinessKyc from "./KYC/MyBusinessKyc";
import BankDetailsKyc from "./KYC/BankDetailsKyc";
import DocumentKyc from "./KYC/DocumentKyc";

export const KycComponents = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);

  console.log(currentStep, "currentStep")
  const handleNext = () => {
    setCurrentStep((prev) => Math.min(prev + 1, tabs.length));
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));

    if (currentStep > 1) {
      const previousStep = currentStep - 1;
      setCurrentStep(previousStep); // Move to the previous step

      // Store the step in localStorage
      localStorage.setItem('currentStep', previousStep);

      // Navigate to the appropriate route based on the step
      if (previousStep === 1) {
        navigate('/kycComponents#personalinfokyc'); // Update the route for the first step
      } else if (previousStep === 2) {
        navigate('/kycComponents#mybussinesskyc'); // Update the route for the second step
      } else if (previousStep === 3) {
        navigate('/kycComponents#bankdetailskyc'); // Update the route for the third step
      }
    }
  };
  const setProgressBar = (step) => {
    const percent = (step / tabs.length) * 100;
    return percent.toFixed();
  };



 



  const tabs = [
    { title: "Personal Info KYC", content: <PersonalInfoKyc setCurrentStep={setCurrentStep} handlePrevious={handlePrevious} currentStep={currentStep} handleNext={handleNext} setProgressBar={setProgressBar} /> },
    { title: "My Business KYC", content: <MyBusinessKyc setCurrentStep={setCurrentStep} handlePrevious={handlePrevious} currentStep={currentStep} handleNext={handleNext} setProgressBar={setProgressBar} /> },
    { title: "Bank Details KYC", content: <BankDetailsKyc setCurrentStep={setCurrentStep} handlePrevious={handlePrevious} currentStep={currentStep} handleNext={handleNext} setProgressBar={setProgressBar} /> },
    { title: "Document KYC", content: <DocumentKyc handlePrevious={handlePrevious} setProgressBar={setProgressBar} setCurrentStep={setCurrentStep} currentStep={currentStep} /> },
  ];


   
  return (
    <div id="main" className="p-4">
      <h1 className="font-bold text-2xl text-slate-900 pb-5">
        <SubHeaderMenu location={location} />
      </h1>
      <div>
        <TabsSecond
          tabs={tabs}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          setProgressBar={setProgressBar}
        />
      </div>
    </div>
  );
};
