import React, {  useState } from 'react';
import Button from '../Common/Button';
import useNetworkServices from '../../Networking/baseAPIManager';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../Components/Common/Toastify';

import { FaFileUpload } from 'react-icons/fa';

const DocumentKyc = ({ handlePrevious, currentStep  }) => {
    const { UpdateProfile } = useNetworkServices();
    const navigate = useNavigate();
    const [fileName, setFileName] = useState('');
    const [formData, setFormData] = useState({
        aadhar: '',
        panCard: '',
    });

   

    const [error, setErrors] = useState({});
    const validateForm = () => {
        const newError = {};

        // Aadhar Card validation
        if (!formData.aadhar) {
            newError.aadhar = 'Aadhar Card  is required';
        }

        // Pan Card validation
        if (!formData.panCard) {
            newError.panCard = 'Pan Card  is required';
        }

        setErrors(newError);
        return Object.keys(newError).length === 0
    }

   

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const data = new FormData();
            Object.keys(formData).forEach((key) => {
                data.append(key, formData[key]);
            });

            try {
                const response = await UpdateProfile(data);
                
                console.log(response.data, "ResponseData");
                showToast('KYC Complete successfully!', 'complete'," bg-green, text-[10px], text-white");

                setTimeout(() => {
                    navigate('/MyProfile');

                }, 3000)
            } catch (error) {
                console.error("Update Profile error:", error);
            }
        }

    };
 
   

    const handleFileChange = (e, fieldName) => {
        const file = e.target.files[0];

        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: file, // Update the selected file
        }));

        setFileName((prevNames) => ({
            ...prevNames,
            [fieldName]: file ? file.name : '', // Set the file name
        }));

        const newError = { ...error };
        if (file) {
            delete newError[fieldName]; // Remove the error for the selected file
        }
        setErrors(newError);
    };

    const inputFields = [
        { name: 'aadhar', type: 'file', label: 'Aadhar Card', placeholder: 'Aadhar Card' },
        { name: 'panCard', type: 'file', label: 'Pan Card', placeholder: 'Pan Card' },
    ];

    return (
        <>
        <div className='pt-8'>
            <form className="space-y-4 md:space-y-6" onSubmit={handleFormSubmit}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
                        
                        {inputFields.map((field) => (
                            <div key={field.name}>
                                <label
                                    htmlFor={field.name}
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                                >
                                    {field.label}
                                </label>
                                <div className="relative w-full">
                                    <input
                                        type="file"
                                        name={field.name}
                                        id={field.name}
                                        className="hidden"
                                        onChange={(e) => handleFileChange(e, field.name)}
                                    />
                                    <label
                                        htmlFor={field.name}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 block w-full cursor-pointer flex items-center justify-between"
                                    >
                                        <span className="flex items-center">
                                           {/* File icon */}
                                            {fileName[field.name] ? fileName[field.name] : 'Choose file'}
                                        </span>
                                        <FaFileUpload className="mr-2" /> {/* Custom browse button */}
                                    </label>
                                </div>
                                {error[field.name] && (
                                    <div className="text-red-500 text-sm">{error[field.name]}</div>
                                )}
                            </div>
                        ))}

                </div>

                    <div className=' xl:flex lg:flex md:flex  xm:flex   sm:flex justify-between gap-4 pt-5 xl:pt-0 lg:pt-0'>
                    <div>
                        <Button type='button' text='Previous' className="border-custom-red border hover:border-custom-red w-full xl:mt-0 lg:mt-0  hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300" onClick={handlePrevious} disabled={currentStep === 1} />
                    </div>
                    <div className='xl:flex lg:flex md:flex  flex sm:flex gap-4  pt-5 xl:pt-0 lg:pt-0'>
                       
                        <Button
                            type="submit"
                            className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
                            text="Next"
                        />
                    </div>


                </div>
            </form>
        </div>

         
   </>
    );
};

export default DocumentKyc;

 