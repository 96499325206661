import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import SubHeaderMenu from "./Sidebar/SubHeaderMenu";
import { Table } from "./Common/Table/Table";
import { GrView } from "react-icons/gr";
import { FaFileUpload } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Button from "./Common/Button"; // Correct path for Button component
import useNetworkServices from "../Networking/baseAPIManager";
import Modal from "./Common/Modal ";

// import OfferImg1 from "../assets/images/home/offer1.png";
import CardBannerImage from "../assets/images/Offers/CardBannerImage.png";
import mobile from "../assets/images/Offers/mobile.png";
import CardBanner from "./Common/Cards/CardBanner";

const CreateBanner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { GetBanner, AddBanner, AddBannerUpload } = useNetworkServices();
  const [modalContent, setModalContent] = useState({});
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [error, setErrors] = useState({});
  const [rowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({
    id: "",
    image: "",
    tagLine: "",
    shopName: "",
    minimumPurchage: "",
    cashBackReward: "",
    image: "",
    startData: "",
    endDate: "",
  });
  const formRef = useRef(null);
  const currentDate = new Date();
  const formatedCurrentDate = currentDate.toISOString().split("T")[0];
  const customBtn = (data) => {
    const isEditable = data.status.toLowerCase() === "pending";
    return (
      <div className="flex gap-2">
        <GrView
          onClick={() => handleItemClick(data)}
          className="text-xl text-red-500 cursor-pointer hover:text-red-600"
        />
        <MdOutlineModeEditOutline
          onClick={() => handleEditClick(data)}
          className={`text-2xl cursor-pointer ${
            isEditable ? "text-gray-500 hover:text-gray-600" : "text-gray-300 cursor-not-allowed"
          }`}
          style={{ pointerEvents: isEditable ? "auto" : "none" }}
        />
      </div>
    );
  };
  const inputFields = [
    { name: "tagLine", type: "text", label: "Tag Line", placeholder: "Tag Line" },
    { name: "shopName", type: "text", label: "Shop name", placeholder: "Shop name" },
    {
      name: "minimumPurchage",
      type: "text",
      label: "Minimum Purchase Amount (Cash Fuse)",
      placeholder: "Minimum Purchase Amount (Cash Fuse)",
    },
    { name: "cashBackReward", type: "text", label: "Cashback", placeholder: "Cashback / Reward" },
    { name: "startData", type: "date", label: "Start Date", placeholder: "Start Date", min: formatedCurrentDate },
    { name: "endDate", type: "date", label: "End Date", placeholder: "End Date" },
  ];

  const handleEditClick = (data) => {
    setFormData({
      id: data?._id || "",
      tagLine: data?.tagLine || "",
      shopName: data?.shopName || "",
      minimumPurchage: data?.minimumPurchage || "",
      cashBackReward: data?.cashBackReward || "",
      startData: data?.startData || "",
      endDate: data?.endDate || "",
      image: data?.image || "",
    });

    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleItemClick = (data) => {
    setModalContent(data);
    setOpen(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    if (!formData.tagLine) {
      errors.tagLine = "Tagline is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.tagLine)) {
      errors.tagLine = "Tagline can only contain text";
    }

    if (!formData.shopName) {
      errors.shopName = "Shop Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.shopName)) {
      errors.shopName = "Shop Name can only contain text";
    }
    if (!formData.minimumPurchage) {
      errors.minimumPurchage = "CashFuse Name is required";
    } else if (!/^\d+$/.test(formData.minimumPurchage)) {
      errors.minimumPurchage = "CashFuse must be numeric only";
    }
    if (!formData.cashBackReward) {
      errors.cashBackReward = "CashBack is required";
    } else if (!/^\d+$/.test(formData.cashBackReward)) {
      errors.cashBackReward = "CashBack must be numeric only";
    }

    const startDate = new Date(formData.startData);

    const endDate = new Date(formData.endDate);

    // Check if startData is empty
    if (!formData.startData) {
      errors.startData = "Start Date is required";
    }
    // else if (startDate < currentDate) {
    //   errors.startData = "Start Date must be current date ";
    // }

    if (!formData.endDate) {
      errors.endDate = "End Date is required";
    } else if (endDate <= startDate) {
      errors.endDate = "End Date must be after Start Date";
    }
    if (!formData.image) {
      errors.image = "Please upload an image";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("id", formData.id);
    formDataToSend.append("tagLine", formData.tagLine);
    formDataToSend.append("shopName", formData.shopName);
    formDataToSend.append("minimumPurchage", formData.minimumPurchage);
    formDataToSend.append("cashBackReward", formData.cashBackReward);
    formDataToSend.append("startData", formData.startData);
    formDataToSend.append("endDate", formData.endDate);

    if (formData.image) {
      formDataToSend.append("image", formData.image);
    }

    try {
      const response = formData.id
        ? await AddBannerUpload({ id: formData.id, ...formData })
        : await AddBanner(formDataToSend);

      console.log("Response:", response);
      fetchCashFuseData();
      handleCancel();
    } catch (error) {
      console.error("Error adding banner:", error);
      alert("An error occurred while adding the banner.");
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    const { name, value, files } = e.target;
    const newError = { ...error };
    if (name === "tagLine" && !value) {
      newError.tagLine = "Tage Line is required";
    } else if (name === "shopName" && !value) {
      newError.shopName = "Shop name is required";
    }
    {
      delete newError[name]; 
    }

    setErrors(newError);
  };
  const handleCancel = () => {
    setFormData({
      image: "",
      tagLine: "",
      shopName: "",
      minimumPurchage: "",
      cashBackReward: "",
      image: "",
      startData: "",
      endDate: "",
    });
    setFileName("");
  };

  const fetchCashFuseData = async () => {
    try {
      const response = await GetBanner();

      setTableData(response.data.data);
    } catch (error) {
      console.error("Error fetching cash fuse offers:", error);
    }
  };

  useEffect(() => {
    fetchCashFuseData();
  }, [GetBanner]);

  const columns = [
    { header: "Shop name", accessor: "shopName" },
    { header: "Tag Line", accessor: "tagLine" },
    { header: "Min. Purchase Amount (Cash Fuse)", accessor: "minimumPurchage" },
    { header: "Cashback / Reward", accessor: "cashBackReward" },
    { header: "Start Date", accessor: "startData", min: formatedCurrentDate },
    { header: "End Date", accessor: "endDate" },
    {
      header: "Status",
      accessor: "status",
      customBtn: (data) => (
        <span style={{ color: data.status?.toLowerCase() === "pending" ? "red" : "blue" }}>{data.status ?? "N/A"}</span>
      ),
    },
    {
      header: "Action",
      accessor: "action",
      customBtn,
    },
  ];
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setFormData((prevData) => ({
      ...prevData,
      image: file,
    }));

    setFileName(file ? file.name : "");
    const newError = { ...error };
    if (file) {
      delete newError.image;
    }
    setErrors(newError);
  };
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentTableData = tableData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(tableData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  return (
    <div id="main" className="p-4">
      <h1 className="font-bold text-2xl text-slate-900 pb-5">
        <SubHeaderMenu location={location} />
      </h1>

      {/* Form Section */}
      <form className="space-y-4 md:space-y-6 pt-6" onSubmit={handleFormSubmit} ref={formRef}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
          {inputFields.map((field) => (
            <div key={field.name}>
              <label htmlFor={field.name} className="block mb-2 text-sm font-bold text-gray-900">
                {field.label}
              </label>
              <input
                type={field.type}
                name={field.name}
                id={field.name}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none"
                placeholder={field.placeholder}
                value={formData[field.name]}
                onChange={handleChange}
                min={field.min}
              />
              {error[field.name] && <span className="text-red-500 text-sm">{error[field.name]}</span>}
            </div>
          ))}
          <div>
            <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
              Product Image
            </label>

            <div className="w-full">
              <input type="file" name="image" id="image" className="hidden" onChange={handleFileChange} />
              <label
                htmlFor="image"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 block w-full cursor-pointer flex items-center justify-between"
              >
                <span className="flex items-center">{fileName ? fileName : "Choose file"}</span>
                <FaFileUpload className="mr-2" />
              </label>
            </div>
            {error.image && <div className="text-red-500 text-sm">{error.image}</div>}
          </div>
        </div>
        <div className="xl:flex lg:flex md:flex sm:flex justify-end gap-4 pt-5 xl:pt-0 lg:pt-0">
          <Button
            type="button"
            className="border-custom-red border hover:border-custom-red w-full xl:mt-0 lg:mt-0 hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
            text="Cancel"
            onClick={handleCancel}
          />
          <Button
            type="submit"
            className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
            text="Save Changes"
          />
        </div>
      </form>

      <div className="p-4 bg-white shadow-md rounded-md mt-8">
        <Table columns={columns} data={tableData} />
      </div>
      <Modal isOpen={open} onClose={() => setOpen(false)} title="Banner Image" size="xl">
        <CardBanner
          tagline={modalContent.tagLine}
          title={modalContent.shopName}
          bannerProducImage={modalContent.image}
          cashBackReward={modalContent.cashBackReward}
          buttonText="Shop Now"
          onButtonClick={() => alert("Shop Now Clicked")}
          backgroundImage={CardBannerImage}
        />
      </Modal>
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-100 text-black rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gray-100 text-black rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CreateBanner;
