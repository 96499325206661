import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const Modal = ({ isOpen, onClose, title, children, size = "md" }) => {
  if (!isOpen) return null;

  const modalWidth = {
    sm: "max-w-sm",
    md: "max-w-lg",
    lg: "max-w-3xl",
    xl: "w-full md:w-4/5 lg:w-3/5",
    full: "w-full max-w-full",
  };

  return (
    // <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 overflow-y-auto">
    <div
      className={`bg-white rounded-lg shadow-lg p-6 relative w-full mx-4 ${modalWidth[size]} sm:mx-6 md:mx-8 lg:mx-auto`}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <AiOutlineClose
        onClick={onClose}
        className="absolute top-3 right-3 text-gray-600 cursor-pointer hover:text-gray-800"
        size={24}
      />

      <h2 className="text-lg font-bold mb-4 text-center">{title}</h2>

      <div className="mb-4">{children}</div>
    </div>
    // </div>
  );
};

export default Modal;
