import React from 'react';
import { QRCodeCanvas } from 'qrcode.react'; // Adjusted import

const QRcode = () => {
    const jsonData = JSON.stringify({
        email: "kiran@singhsoft.com",
        phone: "9699999996",
        id: "66a47b1df062b98958e0003b",
        firstName : "kiran"
    });
    let imageUrl = "";
    let imageSettings = {
        src: imageUrl,
        height: 50,
        width: 50,
        excavate: false,
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "40px" }}>
            <QRCodeCanvas
                value={jsonData}
                size={256}
                fgColor="#000000"
                bgColor="#FFFFFF"
                level="H"
                imageSettings={imageSettings}
                includeMargin={true}
            />
        </div>
    );
};

export default QRcode;
