import React, { useEffect, useState } from "react";
import Button from "../Common/Button";
import useNetworkServices from "../../Networking/baseAPIManager";
import { useNavigate } from "react-router-dom";

const PersonalInfo = ({ profileData, onImageChange, profileImage, onProfileUpdate }) => {
  const { UpdateProfile } = useNetworkServices();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    address: "",
    pinCode: "",
    city: "",
    state: "",
    profileImage: profileImage,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (profileData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        firstName: profileData?.firstName || "",
        lastName: profileData?.lastName || "",
        email: profileData?.email || "",
        mobile: profileData?.mobile || "",
        address: profileData?.address || "",
        pinCode: profileData?.pinCode || "",
        city: profileData?.city || "",
        state: profileData?.state || "",
        profileImage: profileImage,
      }));
    }
  }, [profileImage, profileData]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) newErrors.email = " Email is Required";

    if (!formData.mobile) {
      newErrors.mobile = "Phone Number is Required";
    }
    if (!formData.address) newErrors.address = "Address is required";
    if (!formData.pinCode) newErrors.pinCode = "Pincode is Required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "mobile" && !/^\d*$/.test(value)) {
      return;
    }
    if (name === "pinCode" && !/^\d*$/.test(value)) {
      return;
    }
    const newErrors = { ...errors };

    if (name === "firstName" && !value) {
      newErrors.firstName = "First name is required";
    } else if (name === "lastName" && !value) {
      newErrors.lastName = "Last name is required";
    } else if (name === "email") {
      if (!value) {
        newErrors.email = "Email Is Required";
      } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) {
        newErrors.email = "Invalid email address";
      } else {
        delete newErrors.email;
      }
    } else if (name === "mobile") {
      if (!value) {
        newErrors.mobile = "Phone Number is Required";
      } else if (!/^\d{10}$/.test(value)) {
        newErrors.mobile = "Phone Number must be exactly 10 digits";
      } else if (/^(.)\1{9}$/.test(value)) {
        newErrors.mobile = "Phone number invalid";
      } else {
        delete newErrors.mobile; // Remove error if the input is valid
      }
    } else if (name === "address" && !value) {
      newErrors.address = "Address is required";
    } else if (name === "pinCode" && !/^\d*$/.test(value) && !/^\d{6}$/.test(value)) {
      newErrors.pinCode = "Pincode must be a valid 6-digit number";
    } else if (name === "city" && !value) {
      newErrors.city = "City is required";
    } else if (name === "state" && !value) {
      newErrors.state = "State is required";
    } else {
      delete newErrors[name]; // Remove any error if the input is valid
    }

    setErrors(newErrors);

    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
    if (name === "image" && files) {
      onImageChange(files[0]);
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        data.append(key, formData[key]);
      });

      try {
        const response = await UpdateProfile(data);
        console.log(response.data, "ResponseData");

        if (response.data && response.data.profileImage) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            profileImage: response.data.profileImage,
            
          }));
          
          onImageChange(response.data.profileImage);
          console.log(response.data.profileImage,"Profile====>");
        }

        onProfileUpdate();
        navigate("/MyProfile#my-business");
      } catch (error) {
        console.error("Update Profile error:", error);
      }
    }
  };

  const handleCancel = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      address: "",
      pinCode: "",
      city: "",
      state: "",
      profileImage: "",
    });
    navigate("/MyProfile");
  };

  return (
    <div className="pt-8">
      <form className="space-y-4 md:space-y-6" onSubmit={handleFormSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
          {[
            { name: "firstName", type: "text", label: "First Name", placeholder: "First name" },
            { name: "lastName", type: "text", label: "Last Name", placeholder: "Last name" },
            { name: "email", type: "email", label: "Email", placeholder: "Email" },
            { name: "mobile", type: "tel", label: "Phone Number", placeholder: "Phone number" },
            { name: "address", type: "text", label: "Address", placeholder: "Address" },
            { name: "pinCode", type: "tel", label: "Pincode", placeholder: "Pincode" },
            { name: "city", type: "text", label: "City", placeholder: "City" },
            { name: "state", type: "text", label: "State", placeholder: "State" },
          ].map((field) => (
            <div key={field.name}>
              <label htmlFor={field.name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
                {field.label}
              </label>
              <input
                type={field.type}
                name={field.name}
                id={field.name}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                placeholder={field.placeholder}
                value={formData[field.name]}
                onChange={handleChange}
              />
              {errors[field.name] && <div className="text-red-500 text-sm">{errors[field.name]}</div>}
            </div>
          ))}
        </div>

        <div className="xl:flex lg:flex md:flex  flex sm:flex justify-end gap-4 pt-5 xl:pt-0 lg:pt-0">
          <Button
            type="button"
            className="border-custom-red border hover:border-custom-red w-full xl:mt-0 lg:mt-0  hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
            text="Cancel"
            onClick={handleCancel}
          />
          <Button
            type="submit"
            className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
            text="Save Changes"
          />
        </div>
      </form>
    </div>
  );
};

export default PersonalInfo;
