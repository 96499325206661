 
import React, { useState } from "react";
import BG1 from "../../../assets/images/AboutUs/bg.png"; 
import BG2 from "../../../assets/images/AboutUs/bg1.png"; 
import AboutusImage2 from "../../../assets/images/AboutUs/bg-side.png";
import AboutusImage3 from "../../../assets/images/AboutUs/bg-slide1.png";
import { MdOutlineStorefront, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Button from "../../Common/Button";
const AboutUs = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const images = [
    {
      src: BG1,
      heading: "Discover Your City",
      description: "Get Rewarded For Doing Things You Love",
     
      color: "rgba(231, 57, 33, 1)",
      LeftImg: AboutusImage2,
     
    },
    {
      src: BG2,
      heading: "Retail Campus",
      description: "Get Rewarded For Doing Things You Love",
      icon: <MdOutlineStorefront className="mr-2" />,
      color: "rgba(0, 142, 204, 1)",
      LeftImg: AboutusImage3,
    },
  ];

  const handleNext = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? images.length - 1 : prevImage - 1
    );
  };

  return (
    <div className="container mx-auto px-4 relative py-20">
      {/* Background Image Section */}
      <div
        className="relative bg-no-repeat bg-cover rounded-lg text-white grid grid-cols-1 lg:grid-cols-2 items-center"
        style={{
          backgroundImage: `url(${images[currentImage].src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="p-20 lg:p-12 m-auto">
          <h2 className="font-['Roboto Slab'] text-5xl sm:text-6xl md:text-5xl font-bold flex items-center">
            {images[currentImage].icon}
            {images[currentImage].heading}
          </h2>
          <p className="font-['Roboto'] text-[15px] sm:text-[16px] mb-4 md:text-[18px] font-normal mt-4">
            {images[currentImage].description}
          </p>
          <a href="/contactus"> <Button
            type='button'
            className="border-white border  hover:border-white   mt-3 xl:mt-0 lg:mt-0 sm:mt-3 md:mt-3 hover:text-custom-red text-white font-bold py-2 px-4 rounded-lg hover:bg-white transition duration-300"
            text=" Contact Us"
          /></a>
        </div>
        <div className="hidden lg:block"> 
          <img src={images[currentImage].LeftImg} alt="" className=" m-auto p-10 object-cover" />
        </div>

        {/* Navigation Buttons */}
        <button
          className="rounded-full border-8 border-white  flex items-center justify-center absolute left-0 transform -translate-x-1/2 top-1/2 -translate-y-1/2"
          style={{
            backgroundColor: "rgba(243, 249, 251, 1)",
            color: images[currentImage].color,
            width: "60px",
            height: "60px",
          }}
          onClick={handlePrev}
        >
          <MdKeyboardArrowLeft size={24} />
        </button>
        <button
          className="rounded-full border-8 border-white  flex items-center justify-center absolute right-0 transform translate-x-1/2 top-1/2 -translate-y-1/2"
          style={{
            backgroundColor: "rgba(243, 249, 251, 1)",
            color: images[currentImage].color,
            width: "60px",
            height: "60px",
          }}
          onClick={handleNext}
        >
          <MdKeyboardArrowRight size={24} />
        </button>
      </div>

      {/* About Us Section */}
      <section className="font-['Roboto'] mt-8 md:mt-16">
        <h3 className="text-[#025D89] font-semibold text-2xl md:text-[35px]">
          About Us
        </h3>
        <p className="text-[#E73921] font-medium py-2 md:py-4 text-lg">
          Discovery and savings for local shopping. Food, fashion, beauty,
          pharmacy, and more.
        </p>
        <p className="text-[#857885] font-light text-base md:text-lg">
          Discovery and savings for local shopping. Food, fashion, beauty,
          pharmacy, and more. Since 2015, magicpin has been transforming the
          offline shopping experience in India. It brings alive the joy of
          shopping and savings from local stores, which are the lifeblood of the
          economy. By connecting retailers big and small with customers,
          magicpin creates value for all in the hyperlocal retail ecosystem and
          allows them to leverage the fast-growing digital world.
          <br />
          magicpin drives the discovery of brands and retailers across
          categories like fashion,  food,  electronics,  grocery,  pharmacy,  home
          delivery, spa, nightlife, entertainment, and more. It builds
          engagement and visibility for retailers and brands to help boost
          demand and offers an omnichannel platform to connect with relevant
          local customers.
          <br /> For shopping at local stores, customers are rewarded with
          savings and magicPoints on their purchase, which they use to shop more
          at all stores. From the biggest brands to local staples, customers get
          savings, discounts, vouchers, and rewards for simply choosing to shop
          local.
          <br /> magicpin exists to create a marketplace that is more local,
          relevant, and celebrates the thrill of shopping and savings for all.
          That’s the magic of local.
        </p>
      </section>

      {/* Partners Section */}
      <section className="font-['Roboto'] pt-8 md:pt-12">
        <h3 className="text-[#025D89] font-semibold text-2xl md:text-[35px] pb-8 md:pb-12">
          Some Of Our Partners
        </h3>
        <div className="w-full h-32 bg-gradient-to-b from-[rgba(2,93,137,0.02)] to-[rgba(2,93,137,0.06)] flex justify-center items-center">
          <div className="flex flex-wrap justify-center gap-8 md:gap-36">
            <img
              src="./image/Google.svg"
              className="w-[60px] h-auto lg:w-[100px] xl:w-[150px]"
              alt="Google Logo"
            />
            <img
              src="./image/airbnd.svg"
              className="w-[80px] h-auto lg:w-[130px] xl:w-[166px]"
              alt="Airbnb Logo"
            />
            <img
              src="./image/UberEatsLogo.svg"
              className="w-[70px] h-auto lg:w-[140px] xl:w-[190px]"
              alt="Uber Eats Logo"
            />
            <img
              className="w-[60px] h-auto lg:w-[110px] xl:w-[142px]"
              src="./image/AmazonLogo.svg"
              alt="Amazon Logo"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
