import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sucessImg from "../../assets/images/dashboardImg/success.gif";
const toastId = "redeemCashToast";

export const showToast = (message, type = "default", customClass = "") => {
  const toastTypes = {
    success: () => toast.success(message, { className: `${customClass} bg-green-500 text-white` }),
    error: () => toast.error(message, { className: "bg-red-500 text-white" }),
    info: () => toast.info(message, { className: "bg-blue-500 text-white" }),
    warning: () => toast.warn(message, { className: "bg-yellow-500 text-black" }),
    default: () => toast(message, { className: "bg-gray-800 text-white" }),

    complete: () =>
      toast(
        <div className="flex flex-col items-center">
          <img src={sucessImg} alt="check" className="w-40 h-40 mb-2" />
          <span>{message}</span>
        </div>,

        {
          className: `${customClass} bg-green-500 text-white text-center p-4 rounded-xl shadow-lg`,
          style: {
            position: "fixed",
            top: "50%",
            left: "50%",
            backgroundColor: "green",
            color: "white",
            fontSize: "12px",
          },
        }
      ),

    // redeemCash: () => {
    //   if (!toast.isActive(toastId)) {
    //     toast(
    //       <div className="text-center">
    //         <p>Are You Want To Convert Cash?</p>
    //         <div className="mt-2 flex justify-center gap-4">
    //           <button className="bg-green-500 text-white py-1 px-4 rounded" onClick={() => handleConvertCash(true)}>
    //             Yes
    //           </button>
    //           <button className="bg-red-500 text-white py-1 px-4 rounded" onClick={() => handleConvertCash(false)}>
    //             No
    //           </button>
    //         </div>
    //       </div>,
    //       {
    //         className: "bg-gray-800 text-white",
    //         toastId: toastId,
    //         position: "top-center",
    //         style: {
    //           width: "fit-content",
    //           top: "500%",
    //           left: "50%",
    //         },
    //         autoClose: true,
    //         closeOnClick: true,
    //       }
    //     );
    //   }
    // },
  };

  (toastTypes[type] || toastTypes.default)();
};

// const handleConvertCash = (isConfirmed) => {
//   if (isConfirmed) {
//     console.log("Cash conversion confirmed.");
//     toast.dismiss(toastId);
//   } else {
//     console.log("Cash conversion cancelled.");
//     toast.dismiss(toastId);
//   }
// };

export const Toastify = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  );
};
