import React from 'react';
import Button from '../../Common/Button';
import useInView from '../../Common/useInView';
import Logo from "../../../assets/images/logo/LOGO.png";
import { Link } from 'react-router-dom';

const TrendingStore = ({ TopOfferData }) => {
    const [ref, isInView] = useInView();

    // if (!TopOfferData || !Array.isArray(TopOfferData.offers)) {
    //     return null;
    // }
    console.log(TopOfferData, "TopOfferData==?")

    const BackgroundColor = ["#E7F0F5", "#E4E7F2", "#F4E2D4"];

    const merchants = TopOfferData?.merchant || [];
    const offers = TopOfferData?.offers || [];
    console.log(merchants,"merchants")
  
 
    return (
        <>
            <section className="py-10" ref={ref}>
                <div className="container m-auto">
                    <div className="mb-10 p-4 lg:p-0">
                        <h2 className={`font-bold text-custom-blue lg:text-[50px] text-[40px] md:text-[45px] ${isInView ? 'animate-fadeInLeft opacity-100' : 'opacity-1'}`}
                            style={{ animationDelay: '0s' }}>Trending CashFuse Store</h2>
                    </div>
                    <div className="grid grid-cols-12 xl:gap-6 lg:gap-6 items-center pt-4">
                        {TopOfferData.offers.map((trendingStore, index) => {
                            
                            const shopNameStore = trendingStore?.merchantId?.businessDetils?.shopName || "Shop name";
                            const merchantIdExists = trendingStore?.merchantId?._id; 

                            // const merchantDetails = merchants.find(
                            //     merchant => merchant._id == merchantIdExists
                            // );
                            

                            const merchantDetails = merchants.find(merchant => merchant._id === trendingStore.merchantId?._id);
                           
                            const workingHour = merchantDetails?.businessDetils?.workingHours || "Time Unavailable";
                            const PhNumber = merchantDetails?.businessDetils?.business_mobileNo || "Phone number not available";
                            const Address = merchantDetails?.businessDetils?.businessAddress || "Address not available";
                            const Terms = merchantDetails?.terms[0]?.description || "Terms not available";
                            const storeOffer = merchantDetails?.storeOffer
                            console.log(storeOffer, "merchantDetails2222222222222222")


                            
                            

                            return (
                                <div key={merchantIdExists} className="col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4">
                                    <div className="items-center DFlex rounded-md min-h-56 p-8 m-5 bg-cover bg-center"
                                        style={{ backgroundColor: BackgroundColor[index % BackgroundColor.length] }}>
                                        <div>
                                            <h4 className="text-[22px] font-bold text-gray-800 font-serif">{shopNameStore}</h4>
                                            <p className="mt-2 mb-5 text-gray-400">
                                                Up To
                                                <span className="text-custom-blue font-semibold"> {trendingStore.storeOffer} </span>
                                                CashFuse off
                                            </p>

                                            <Link to="/shopdetail" state={{
                                                shopName: shopNameStore,
                                                workingHour: workingHour,
                                                PhNumber: PhNumber,
                                                Address: Address,
                                                Terms: Terms,
                                                merchantDetails: merchantDetails, 
                                                storeOffer: storeOffer
                                            }}>
                                                <Button text="Shop Now" className='bg-custom-red text-white font-bold py-2 px-4 rounded hover:bg-custom-orenge transition duration-300' />
                                            </Link>
                                        </div>


                                        <div className='flex justify-center pt-4'>
                                            <img src={trendingStore.image ? trendingStore.image : Logo} alt="Shop img" />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </div>
            </section>
        </>
    );
};

export default TrendingStore;







