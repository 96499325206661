import React from 'react';
import BrandImg1 from '../../../assets/images/home/b1.png';
import BrandImg2 from '../../../assets/images/home/b2.png';
import BrandImg3 from '../../../assets/images/home/b3.png';
import BrandImg4 from '../../../assets/images/home/b4.png';
import BrandImg5 from '../../../assets/images/home/b5.png';
import BrandImg6 from '../../../assets/images/home/b6.png';

const Brands = () => {
    // Step 1: Create an array of brand data
    const brands = [
        { id: 1, imgSrc: BrandImg1, alt: 'Brand Image 1' },
        { id: 2, imgSrc: BrandImg2, alt: 'Brand Image 2' },
        { id: 3, imgSrc: BrandImg3, alt: 'Brand Image 3' },
        { id: 4, imgSrc: BrandImg4, alt: 'Brand Image 4' },
        { id: 5, imgSrc: BrandImg5, alt: 'Brand Image 5' },
        { id: 6, imgSrc: BrandImg6, alt: 'Brand Image 6' },
        


    ];

    return (
        <>
            <section className='pb-20'>
                <div className="container m-auto">
                    <div className='mb-10 p-4 text-center lg:p-0'>
                        <h2 className='font-bold text-custom-blue lg:text-[50px] text-[40px] md:text-[45px]'>
                            Top Brands
                        </h2>
                        <p className='text-sm sm:text-base lg:text-lg text-gray-400 pt-1'>
                            Up To <span className='text-yellow-500'>30%</span> off on brands
                        </p>
                    </div>

                    <div className="grid grid-cols-12 xl:gap-6 lg:gap-6 items-center pt-4">
                        {/* Step 2: Use .map() to iterate over the brand array */}
                        {brands.map((brand) => (
                            <div key={brand.id} className="col-span-4 lg:col-span-2 xl:col-span-2 md:col-span-2 sm:col-span-3">
                                <div className="items-center justify-center rounded-md p-6 bg-white shadow-custom">
                                    <img src={brand.imgSrc} alt={brand.alt} className="w-full " />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Brands;
