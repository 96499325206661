import React, { useEffect, useState } from "react";
import Button from "../Common/Button";
import useNetworkServices from "../../Networking/baseAPIManager";
import { useNavigate } from "react-router-dom";
import { FaFileUpload } from "react-icons/fa";

const MyBusinessKyc = ({ handleNext, currentStep, handlePrevious }) => {
  const { UpdateProfile, Categorylist } = useNetworkServices();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [fileName, setFileName] = useState("");
  const [brandFileName, setBrandFileName] = useState("");
  const [error, setErrors] = useState({});
  const [formData, setFormData] = useState({
    shopImage: [{}],
    shopName: "",
    gstNumber: "",
    workingHours: {
      from: "",
      to: "",
    },
    brandImage: "",
    business_email: "",
    business_mobileNo: "",
    category: "",
    businessAddress: "",
    businessPinCode: "",
    businessCity: "",
    businessState: "",
  });

  const validateForm = () => {
    const newErrors = {};

    if (!formData.shopName) newErrors.shopName = "Shop Name is required";
    if (!formData.brandImage) newErrors.brandImage = "Brand Image is required";
    if (!formData.workingHours) newErrors.workingHours = "  View Shop Hour is required";
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(formData.business_email))
      newErrors.business_email = "Invalid email businessAddress";
    if (!formData.business_mobileNo) {
      newErrors.business_mobileNo = "Phone Number is Required";
    }
    if (!formData.businessAddress) newErrors.businessAddress = "businessAddress is required";
    if (!formData.category) newErrors.category = "Select Category ";
    if (!/^\d{6}$/.test(formData.businessPinCode))
      newErrors.businessPinCode = "businessPinCode must be a valid 6-digit number";
    if (!formData.businessCity) newErrors.businessCity = "businessCity is required";
    if (!formData.businessState) newErrors.businessState = "businessState is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "gstNumber" && !/^\d*$/.test(value)) {
      return;
    }
    if (name === "business_mobileNo" && !/^\d*$/.test(value)) {
      return;
    }

    if (name === "businessPinCode" && !/^\d*$/.test(value)) {
      return;
    }

    if (!formData.workingHours) newErrors.workingHours = "  View Shop Hour is required";
    if (name === "business_mobileNo" && !/^\d*$/.test(value)) {
      return;
    }
    const newErrors = { ...error };

    if (name === "shopName" && !value) {
      newErrors.business_mobileNo = "Shop Name is required";
    } else if (name === "businessAddress" && !value) {
      newErrors.businessAddress = "businessAddress is required";
    } else if (name === "businessCity" && !value) {
      newErrors.businessCity = "businessCity is required";
    } else if (name === "businessState" && !value) {
      newErrors.businessState = "businessState is Required";
    } else if (name === "business_email") {
      const lowerCaseValue = value.toLowerCase();

      if (!lowerCaseValue) {
        newErrors.business_email = "business_email is required";
      } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(lowerCaseValue)) {
        newErrors.business_email = "Invalid business_email businessAddress";
      } else {
        delete newErrors.business_email;
      }
    } else if (name === "brandImage") {
      if (!value) {
        newErrors.brandImage = "Brand Image is required";
      } else {
        delete newErrors.brandImage;
      }
    } else if (name === "business_mobileNo") {
      if (!value) {
        newErrors.business_mobileNo = "Phone Number is Required";
      } else if (!/^\d{10}$/.test(value)) {
        newErrors.business_mobileNo = "Phone Number must be exactly 10 digits";
      } else if (/^(.)\1{9}$/.test(value)) {
        newErrors.business_mobileNo = "Phone number invalid";
      } else {
        delete newErrors.business_mobileNo;
      }
    } else {
      delete newErrors[name];
    }

    setErrors(newErrors);

    if (name === "from" || name === "to") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        workingHours: {
          ...prevFormData.workingHours,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files ? files[0] : value,
      }));
    }
  };
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await Categorylist();
        if (response?.data?.data) {
          console.log(response?.data?.data, "response====>");
          setCategories(response?.data?.data);
        }
        console.log(response, "response22");
      } catch (error) {
        console.error("Category error", error);
      }
    };

    fetchCategories();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = new FormData();
      const updatedFormData = {
        ...formData,
        business_email: formData.business_email.toLowerCase(),
        workingHours: `${formData.workingHours.from} to ${formData.workingHours.to}`,
      };

      Object.keys(updatedFormData).forEach((key) => {
        data.append(key, updatedFormData[key]);
      });
      formData.shopImage.forEach((file) => {
        data.append("shopImage", file);
      });
      try {
        const response = await UpdateProfile(data);

        console.log(response.data, "ResponseData");

        handleNext();
        const nextStep = currentStep + 1;
        localStorage.setItem("currentStep", nextStep);
        navigate("/kycComponents#bankdetailskyc");
      } catch (error) {
        console.error("Update Profile error:", error);
      }
    }
  };
  const handleFileChange = (e) => {
    const { name, value, files } = e.target;

    if (files) {
      const selectedFiles = Array.from(files);
      if (selectedFiles.length > 5) {
        setErrors({ ...error, shopImage: "You can only upload a maximum of 5 files." });
        return;
      }

      const names = selectedFiles.map((file) => file.name);
      setFileName(names);
      setFormData((prevData) => ({ ...prevData, shopImage: selectedFiles }));
      return;
    }

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const handleBrandImageChange = (e) => {
    const { files } = e.target;

    if (files && files.length > 0) {
      const selectedFile = files[0]; // Get the first selected file
      setBrandFileName(selectedFile.name); // Set the name of the selected file
      setFormData((prevData) => ({ ...prevData, brandImage: selectedFile }));
    }
  };
  const handleCancel = () => {
    setFormData({
      logo: "",
      shopName: "",
      gstNumber: "",
      brandImage: "",
      business_email: "",
      business_mobileNo: "",
      category: "",
      businessAddress: "",
      businessPinCode: "",
      businessCity: "",
      businessState: "",
    });
  };

  const [showTimePicker, setShowTimePicker] = useState(false);

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      workingHours: {
        ...prevData.workingHours,
        [name]: value,
      },
    }));
  };

  const handleInputClick = () => {
    setShowTimePicker(true); // Show the time inputs when the input is clicked
  };

  const handleTimeSelection = () => {
    setShowTimePicker(false); // Hide the time pickers after selection
  };
  const inputFields = [
    // { name: 'shopImage', type: 'file', label: 'Shop Image', placeholder: 'Shop Image' },
    { name: "shopName", type: "text", label: "Shop Name", placeholder: "Shop Name" },
    { name: "category", type: "select", label: "Category" },
    // { name: "brandImage", type: "text", label: "Brand Name", placeholder: "Brand Name" },
    { name: "gstNumber", type: "tel", label: "GST Number", placeholder: "GST Number" },
    // { name: 'workingHours', type: 'text', label: 'View Shop Hour', placeholder: 'View Shop Hour' },
    { name: "business_email", type: "email", label: "Business Email", placeholder: "Business Email" },
    { name: "business_mobileNo", type: "tel", label: "Phone Number", placeholder: "Phone Number" },
    { name: "businessAddress", type: "text", label: "Business Address", placeholder: "business Address" },
    { name: "businessPinCode", type: "tel", label: "Business PinCode", placeholder: "business PinCode" },
    { name: "businessCity", type: "text", label: "Business City", placeholder: "business City" },
    { name: "businessState", type: "text", label: "Business State", placeholder: "business State" },
  ];

  return (
    <div className="pt-8">
      <form className="space-y-4 md:space-y-6" onSubmit={handleFormSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
          <div>
            <label htmlFor="shopImage" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
              Shop Image
            </label>

            <div className="w-full">
              <input
                type="file"
                name="shopImage"
                id="shopImage"
                className="hidden"
                multiple
                onChange={handleFileChange}
              />
              <label
                htmlFor="shopImage"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 block w-full cursor-pointer flex items-center justify-between"
              >
                <span className="flex items-center">{fileName.length > 0 ? fileName.join(", ") : "Choose file"}</span>

                <FaFileUpload className="mr-2" />
              </label>
            </div>
            {error.shopImage && <div className="text-red-500 text-sm">{error.shopImage}</div>}
          </div>
          <div>
            <label htmlFor="brandImage" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
              Brand Image
            </label>

            <div className="w-full">
              <input
                type="file"
                name="brandImage"
                id="brandImage"
                className="hidden"
                onChange={handleBrandImageChange}
              />
              <label
                htmlFor="brandImage"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 block w-full cursor-pointer flex items-center justify-between"
              >
                <span className="flex items-center">{brandFileName ? brandFileName : "Choose file"}</span>
                <FaFileUpload className="mr-2" />
              </label>
            </div>
            {error.brandImage && <div className="text-red-500 text-sm">{error.brandImage}</div>}
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900">View Shop Hour</label>
            <input
              type="text"
              name="workingHours"
              onClick={handleInputClick}
              readOnly
              value={`${formData.workingHours.from || "--:--"} to ${formData.workingHours.to || "--:--"}`}
              placeholder="Select shop hours (e.g., 09:00 AM to 06:00 PM)"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer"
            />

            {showTimePicker && (
              <div className="absolute bg-white border border-gray-300 p-4 rounded-lg mt-2 z-10">
                <div className="flex gap-4">
                  <div className="w-1/2">
                    <label className="block text-sm font-medium text-gray-900">From</label>
                    <input
                      type="time"
                      name="from"
                      value={formData.workingHours.from}
                      onChange={handleTimeChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="block text-sm font-medium text-gray-900">To</label>
                    <input
                      type="time"
                      name="to"
                      value={formData.workingHours.to}
                      onChange={handleTimeChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                  </div>
                </div>
                <button
                  type="button"
                  onClick={handleTimeSelection}
                  className="mt-2 bg-blue-500 text-white py-1 px-4 rounded-lg"
                >
                  Apply
                </button>
              </div>
            )}
          </div>

          {inputFields.map((field) => (
            <div key={field.name}>
              <label htmlFor={field.name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
                {field.label}
              </label>

              {field.type === "select" ? (
                <select
                  name={field.name}
                  id={field.name}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                  value={formData[field.name]}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select Category
                  </option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.title}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={field.type}
                  name={field.name}
                  id={field.name}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                  placeholder={field.placeholder}
                  value={formData[field.name]}
                  onChange={handleChange}
                  min={field.min}
                  maxLength={field.maxLength}
                />
              )}
              {error[field.name] && <div className="text-red-500 text-sm">{error[field.name]}</div>}
            </div>
          ))}
        </div>

        <div className=" xl:flex lg:flex md:flex  xm:flex  items-center sm:flex justify-between gap-4 pt-5 xl:pt-0 lg:pt-0">
          <div>
            <Button
              type="button"
              text="Previous"
              className="border-custom-red border  hover:border-custom-red w-full xl:mt-0 lg:mt-0  hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
              onClick={handlePrevious}
              disabled={currentStep === 1}
            />
          </div>
          <div className="xl:flex lg:flex md:flex  flex sm:flex gap-4 pt-5 md:pt-0 sm:pt-0 xl:pt-0 lg:pt-0">
            <Button
              type="button"
              className="border-custom-red border hover:border-custom-red w-full xl:mt-0 lg:mt-0  hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
              text="Cancel"
              onClick={handleCancel}
            />
            <Button
              type="submit"
              className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
              text="Next"
              onClick={handleFormSubmit}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default MyBusinessKyc;
