import React from 'react'
import FoodImg from '../../../assets/images/home/food.gif'
import farmacy from '../../../assets/images/home/farmacy.gif'
import fruit from '../../../assets/images/home/fruit.gif'
import Beauty from '../../../assets/images/home/Beauty.gif'
import electric from '../../../assets/images/home/electronic.gif'
import fashion from '../../../assets/images/home/fashion.gif'



import StorBgImg from '../../../assets/images/slider/stor_bg.png'
const CashFuseStore = () => {

   
    return (
        <>
            <section className='h-64 w-full py-20' style={{ background: `url(${StorBgImg})` }}>
                <div className="container m-auto">
                    <div className='pb-10 animate-fadeInUp'>
                        <h2 className='text-center font-bold text-white lg:text-[50px]  text-[40px] md:text-[45px]'>Find Your Offers</h2>
                    </div>

                </div>

            </section>
            <div className="container m-auto">
                <div className="grid grid-cols-12  xl:gap-6 lg:gap-6 items-center -mt-10 animate-fadeInUp opacity-0" style={{ animationDelay: '0.5s' }}>


                    <div className="col-span-6 lg:col-span-2 md:col-span-3 sm:col-span-4">
                        <div className='text-center m-auto'>
                            <div className="flex m-auto items-center justify-center shadow-lg bg-gray-100 rounded-full p-2 w-32 h-32">
                                <img src={FoodImg} alt="" className="rounded-full" />
                            </div>

                            <h6 className='pt-4'>Food </h6>
                        </div>
                    </div>
                    <div className="col-span-6 lg:col-span-2 md:col-span-3 sm:col-span-4">
                        <div className='text-center'>
                            <div className="flex m-auto items-center justify-center shadow-lg bg-gray-100 rounded-full p-2 w-32 h-32">
                                <img src={fruit} alt="" className="rounded-full" />
                            </div>
                            <h6 className='pt-4'>Fruits & Veg. </h6>
                        </div>
                    </div>
                    <div className="col-span-6 lg:col-span-2 md:col-span-3 sm:col-span-4">
                        <div className='text-center'>
                            <div className="flex m-auto items-center justify-center shadow-lg bg-gray-100 rounded-full p-2 w-32 h-32">
                                <img src={electric} alt="" className="rounded-full" />
                            </div>
                            <h6 className='pt-4'>Electronic </h6>
                        </div>
                    </div>
                    <div className="col-span-6 lg:col-span-2 md:col-span-3 sm:col-span-4">
                        <div className='text-center'>
                            <div className="flex m-auto items-center justify-center shadow-lg bg-gray-100 rounded-full p-2 w-32 h-32">
                                <img src={farmacy} alt="" className="rounded-full" />
                            </div>
                            <h6 className='pt-4'>Pharmacy </h6>
                        </div>
                    </div>
                    <div className="col-span-6 lg:col-span-2 md:col-span-3 sm:col-span-4">
                        <div className='text-center'>
                            <div className="flex m-auto items-center justify-center shadow-lg bg-gray-100 rounded-full p-2 w-32 h-32">
                                <img src={Beauty} alt="" className="rounded-full" />
                            </div>
                            <h6 className='pt-4'>Beauty  </h6>
                        </div>
                    </div>
                    <div className="col-span-6 lg:col-span-2 md:col-span-3 sm:col-span-4">
                        <div className='text-center'>
                            <div className="flex m-auto items-center justify-center shadow-lg bg-gray-100 rounded-full p-2 w-32 h-32">
                                <img src={fashion} alt="" className="rounded-full" />
                            </div>
                            <h6 className='pt-4'>Fashion & Wear  </h6>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default CashFuseStore;
