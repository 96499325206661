import React, { useState } from 'react';
import BackgroundImage from '../../assets/images/img/SIGNUP-BG.png';
import LoginImg from '../../assets/images/img/login.png';
import Button from '../Common/Button';
import { useNavigate,Link } from 'react-router-dom'
import useNetworkServices from '../../Networking/baseAPIManager';
import { HeaderLogo } from './HeaderLogo';




const ForgotPassword = () => { 
  const [formData, setFormData] = useState({ email: '' }); 
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { ForgotPassword } = useNetworkServices();


 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit =async (e) => {
    e.preventDefault();

    const { email } = formData;
    localStorage.setItem('forgotPasswordEmail', email);
    if (!email) {
      setError('Email is required');
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Email address is invalid');
      return;
    }

    setError('');

    try {
      const response = await ForgotPassword(formData);
      console.log(response.data, "ResponseData");
      navigate('/otpvarification');  
    } catch (error) {
      console.error("Sign-in error:", error); 
    }


  };

  return (
    <>
      <div  className='flex items-center justify-center min-h-screen bg-cover bg-center' style={{ backgroundImage: `url(${BackgroundImage})` }}>
        <Link to='/'>
          <HeaderLogo />


        </Link>
        <div className="container p-8 mt-28 lg:m-0 md:m-0">
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
              <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-white dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                    Forgot your password?
                  </h1>
                  <p className='text-gray-400 text-sm'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perferendis fugiat veritatis eligendi necessitatibus.</p>
                  <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" onSubmit={handleSubmit}>
                    <div>
                      <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Your email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                        placeholder="Enter.."
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
                    </div>
                    <Button type="submit" className={"bg-custom-blue w-full text-white font-bold py-2 px-4 rounded hover:bg-custom-blue2 transition duration-300"} text={"Reset Password"} />
                  </form>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
              <img src={LoginImg} alt="Login" className="max-w-full h-auto" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
