import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles for the editor
import "../../assets/styles/Style.css";
const TextEditor = ({ value, onChange }) => {
  const toolbarOptions = [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    ["link", "image"],
    ["clean"], // Remove formatting
  ];

  return <ReactQuill value={value} onChange={onChange} modules={{ toolbar: toolbarOptions }} theme="snow" />;
};

export default TextEditor;
