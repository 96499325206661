import React, { useEffect, useState } from "react";
import CashfuseChart from "./Common/graph/CashfuseChart";
import SubHeaderMenu from "./Sidebar/SubHeaderMenu";
import { useLocation } from "react-router-dom";
import Button from "./Common/Button";
import { IoIosArrowRoundUp } from "react-icons/io";
import CustomerGraph from "./Common/graph/CustomerGraph";
import QRcode from "./Common/QRCode";
import useNetworkServices from "../Networking/baseAPIManager";

const Dashboard = () => {
  const { GetDashboard } = useNetworkServices();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [dashboardData, setDashboardData] = useState({});
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const fetchTransactions = async (year, month) => {
    try {
      const response = await GetDashboard({ params: { year, month } });
      setDashboardData(response.data);
      console.log("Fetched Data:", response.data.data);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setDashboardData({});
    }
  };

  useEffect(() => {
    fetchTransactions(selectedYear, selectedMonth);
  }, [selectedYear, selectedMonth]);
  const cashfuseData = dashboardData?.data?.series || [];
  const cashfusebarData = dashboardData?.data || [];
  const yearOptions = Array.from({ length: 3 }, (_, index) => currentYear - index);
  const monthOptions = Array.from({ length: 12 }, (_, index) => ({
    value: index + 1,
    label: new Date(0, index).toLocaleString("default", { month: "long" }),
  }));
  return (
    <>
      <div id="main">
        <h1 className="font-bold text-2xl text-slate-900 pb-5">
          {" "}
          <SubHeaderMenu location={useLocation()} />
        </h1>

        <div className="grid grid-cols-12">
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-8 p-4 sm:border-r-[0px] xl:border-r-[1px] xl:border-b-[1px]">
            <div className="flex justify-between">
              <div className="dashboard_content">
                <h3 className="text-2xl font-semibold text-gray-600 mb-2">{dashboardData?.data?.firstName}</h3>
                <h6 className="text-sm font-bold text-gray-800 mb-1">{dashboardData?.data?.shopName}</h6>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 pt-10">
              <div className="col-span-1">
                <div className="bg-custom-gradient-red p-6 text-center rounded-3xl">
                  <h4 className="text-5xl text-gray-50">{dashboardData?.data?.totalUser || 34}</h4>
                  <p className="text-gray-100 pt-3 text-md">My customer</p>
                </div>
              </div>
              <div className="col-span-1">
                <div className="bg-custom-gradient-blue p-6 text-center rounded-3xl">
                  <h4 className="text-5xl text-gray-50">{dashboardData?.data?.banner || 34}</h4>
                  <p className="text-gray-100 pt-3 text-md">Active Banner</p>
                </div>
              </div>
              <div className="col-span-1">
                <div className="bg-custom-gradient-yellow p-6 text-center rounded-3xl">
                  <h4 className="text-5xl text-gray-50">{dashboardData?.data?.mytransection || 34}</h4>
                  <p className="text-gray-100 pt-3 text-md">Transaction</p>
                </div>
              </div>
              <div className="col-span-1">
                <div className="bg-custom-gradient-green p-6 text-center rounded-3xl">
                  <h4 className="text-5xl text-gray-50">{dashboardData?.data?.totalCashfuse || 34}</h4>
                  <p className="text-gray-100 pt-3 text-md">My Wallet</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 lg:pb-4 sm:pb-4 sm:border-0 lg:border-b-[1px] xl:col-span-4 xl:border-b-[1px] px-4">
            <div className="flex justify-between pt-4">
              <div className="dashboard_content">
                <h6 className="text-sm font-bold text-gray-600 mb-2">QR Code</h6>
              </div>
            </div>
            <div className="text-center">
              {/* <CustomerGraph /> */}
              <QRcode />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12">
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-8 p-4 sm:border-r-[0px] xl:border-r-[1px] xl:border-b-[1px]">
            <div className="xl:flex lg:flex md:flex sm:flex xs:flex justify-between">
              <div className="dashboard_content">
                <h6 className="text-sm font-semibold text-gray-600 mb-2">Cash Fuse</h6>
                <h3 className="text-2xl font-bold text-gray-800 mb-1">IDR {dashboardData?.data?.yearTransaction}</h3>
                {/* <span className="text-sm text-green-600 flex items-center">
                  <IoIosArrowRoundUp /> 2.1% vs last week
                </span> */}
                <p className="text-xs text-gray-500 mt-2"> Sales from 1-12 Dec, {currentYear}</p>
              </div>
              <div className="pt-5 xl:pt-0 lg:pt-0 md:pt-0  sm:pt-0 xs:pt-0">
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(Number(e.target.value))}
                  className={
                    "bg-white text-custom-blue font-semibold border-2 text-sm py-2 px-4 rounded hover:bg-custom-blue2  hover:text-white transition duration-300"
                  }
                >
                  {yearOptions.map((year) => (
                    <option
                      className={
                        "bg-white text-custom-blue font-semibold  text-sm py-2 px-4 rounded  hover:text-white transition duration-300"
                      }
                      key={year}
                      value={year}
                    >
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <CashfuseChart cashfuseData={cashfuseData} />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-4  sm:border-t-[1px]  lg:pt-6   p-4 ">
            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 lg:pb-4 sm:pb-4   xl:col-span-4   px-4">
              <div className="xl:flex lg:flex md:flex sm:flex xs:flex justify-between pt-4">
                <div className="dashboard_content">
                  <h6 className="text-sm font-bold text-gray-600 mb-2">Cash Fuse Customer</h6>
                  <p className="text-xs text-gray-500 mt-2">From 1-12 Dec,{currentMonth}</p>
                </div>
                <div className="pt-5 xl:pt-0 lg:pt-0 md:pt-0  sm:pt-0 xs:pt-0">
                  <select
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(Number(e.target.value))}
                    className="bg-white text-custom-blue font-semibold border-2 py-2 px-4 rounded hover:bg-custom-blue2 hover:text-white transition duration-300"
                  >
                    {monthOptions.map((month) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <CustomerGraph cashfusebarData={cashfusebarData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
