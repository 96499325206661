import React, { useState, useEffect } from "react";
import BackgroundImage from "../../assets/images/img/SIGNUP-BG.png";
import LoginImg from "../../assets/images/img/login.png";
import Button from "../Common/Button";
import { NavLink, Link, useNavigate } from "react-router-dom";
import useNetworkServices from "../../Networking/baseAPIManager";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { setGetLocalData } from "../../Networking/localStorageHelper";
import { HeaderLogo } from "./HeaderLogo";
const SignIn = () => {
  const { signInMerchant } = useNetworkServices();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: inputValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.password) errors.password = "Password is required";
    if (!formData.password.trim()) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await signInMerchant(formData);
        console.log(response.data, "ResponseData");
        const token = response.data.tokens;
        setGetLocalData("token", token);
        if (response.data.kyc_status) {
          navigate("/dashboard");
        } else {
          navigate("/kycComponents");
        }
      } catch (error) {
        console.error("Sign-in error:", error);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
    }
  }, [errors]);

  return (
    <>
      <div className="  bg-cover bg-center" style={{ backgroundImage: `url(${BackgroundImage})` }}>
        <Link to="/">
          <HeaderLogo />
        </Link>
        <div className="flex items-center justify-center min-h-screen">
          <div className="container mt-28 lg:m-0 md:m-0 ">
            <div className="grid grid-cols-12 gap-8">
              <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
                <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-white dark:border-gray-700">
                  <div className="p-6 space-y-4 md:space-y-6 sm:p-8  ">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                      Sign In
                    </h1>
                    <p className="text-gray-400 text-sm">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perferendis fugiat veritatis eligendi
                      necessitatibus.
                    </p>
                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                      <div>
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                          placeholder="Enter.."
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                      </div>
                      <div className="relative">
                        <label
                          htmlFor="password"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                        >
                          Password
                        </label>
                        <input
                          type={passwordVisible ? "text" : "password"}
                          name="password"
                          id="password"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                          placeholder="Enter.."
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 top-6 flex items-center pr-3"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          {passwordVisible ? (
                            <FaEyeSlash className="text-gray-500" />
                          ) : (
                            <FaEye className="text-gray-500" />
                          )}
                        </button>
                        {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <input
                            id="rememberMe"
                            name="rememberMe"
                            type="checkbox"
                            checked={formData.rememberMe}
                            onChange={handleChange}
                            className="h-4 w-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500"
                          />
                          <label htmlFor="rememberMe" className="ml-2 block text-sm text-gray-900 dark:text-gray-800">
                            Remember me
                          </label>
                        </div>
                        <div className="text-sm">
                          <NavLink
                            to="/forgotpassword"
                            className="font-medium text-primary-600 text-blue-400 hover:underline dark:text-blue-500"
                          >
                            Forgot your password?
                          </NavLink>
                        </div>
                      </div>
                      <div>
                        <Button
                          type="submit"
                          className={
                            "bg-custom-blue w-full text-white font-bold py-2 px-4 rounded hover:bg-custom-blue2 transition duration-300"
                          }
                          text={"Login"}
                        />
                      </div>
                      <p className="text-sm font-light">
                        <span className=" text-gray-500 dark:text-gray-600">Don't have an account?</span>
                        <NavLink
                          to="/signup"
                          className="font-medium text-primary-600 text-blue-400 ps-2 hover:underline dark:text-blue-500"
                        >
                          Sign Up here
                        </NavLink>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
                <img src={LoginImg} alt="Login" className="max-w-full h-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
