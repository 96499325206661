import React, { useEffect, useState } from "react";
import Banner from "./Home/Banner";
import CashFuseStore from "./Home/CashFuseStore";
import CashFusePoint from "./Home/CashFusePoint";
import EOffer from "./Home/EOffer";
import Brands from "./Home/Brands";
import TrendingStore from "./Home/TrendingStore";
import KidsFashion from "./Home/KidsFashion";
import AppDownload from "./Home/AppDownload";
import { Testimonial } from "./Home/Testimonial";
import useNetworkServices from "../../Networking/baseAPIManager";
const Home = () => {

  const { GetTopOffer } = useNetworkServices()
  const [TopOfferData, setTopOfferData] = useState({
    offers: [],
    merchant: []
  });
  console.log(TopOfferData,"TopOfferData11")

  const fatchOfferData = async () => {
    try {
      const response = await GetTopOffer(); 
      // setTopOfferData(response.data.data.offers)
      const offersData = response.data.data.offers;
      const merchantData = response.data.data.merchant;
      setTopOfferData({
        offers: offersData,
        merchant: merchantData
      });
      console.log(merchantData, "merchantData")
      console.log(offersData,"offersData")
      // setTopOfferData(response.data.data.merchant)

    } catch (error) {
      console.error("Error Fatching Offer Data", error)
    }
  }

  useEffect(() => {
    fatchOfferData();
  }, [])
  return (
    <>
      <Banner />
      <CashFuseStore TopOfferData={TopOfferData} />
      <CashFusePoint />
      <EOffer TopOfferData={TopOfferData} />
      <Brands />
      <TrendingStore TopOfferData={TopOfferData} />
      <KidsFashion />
      <AppDownload />
      <Testimonial />
    </>
  );
};
export default Home;
