 

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from '../../assets/images/home/testi1.png'
import Image1 from '../../assets/images/home/testi2.png'

const testimonials = [
  {
    image: Image, // Replace with actual image URL
    name: "Robert Clarkson",
    position: "CEO, Axura",
    feedback:
      "The team at Tectxon industry is very talented, dedicated, well organized and knowledgeable. I was most satisfied with the quality of the workmanship. They did excellent work.",
  },
  {
    image: Image1, // Replace with actual image URL
    name: "Jane Doe",
    position: "CTO, TechCo",
    feedback:
      "The team at Tectxon industry is very talented, dedicated, well organized and knowledgeable. I was most satisfied with the quality of the workmanship. They did excellent work.",
  },
  // Add more testimonials as needed
];

function TestimonialSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    horizontal: true,
    arrows: true,
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="bg-white p-6 sm:p-8 my-3 shadow-lg rounded-lg flex flex-col items-center text-center"
          >
            <img
              src={testimonial.image}
              alt={testimonial.name}
              className="mb-4 rounded-full w-16 h-16 sm:w-24 sm:h-24 object-cover"
            />
            <h3 className="text-lg sm:text-xl font-semibold mb-2 text-gray-800">
              {testimonial.name}
            </h3>
            <p className="text-xs sm:text-sm text-gray-500 mb-4">
              {testimonial.position}
            </p>
            <p className="text-sm sm:text-base text-gray-700">
              {testimonial.feedback}
            </p>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default TestimonialSlider;

