import React from "react";
import Logo from "../../assets/images/logo/LOGO.png";
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from "react-icons/fa";
const Footer = () => {
  return (
    <footer className="bg-[#025D89] py-20">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4  sm:grid-cols-2 gap-8  ">
          {/* First Column */}
          <div className=" ">
            <h4 className="text-white text-2xl font-bold mb-4">
              <img src={Logo} alt="Logo" className="bg-white p-2 rounded-md" />
            </h4>
            <p className="text-gray-300 mb-4">
              Complete your style with awesome clothes from us. Lorem ipsum dolor, sit amet consectetur adipisicing
              elit. Pariatur, quae!
            </p>
            <div className="flex space-x-4">
              <a href="#" className="h-12 w-12 bg-white flex items-center justify-center rounded">
                <FaFacebookF className="text-[#0d6079]" />
              </a>
              <a href="#" className="h-12 w-12 bg-white flex items-center justify-center rounded">
                <FaInstagram className="text-[#0d6079]" />
              </a>
              <a href="#" className="h-12 w-12 bg-white flex items-center justify-center rounded">
                <FaTwitter className="text-[#0d6079]" />
              </a>
              <a href="#" className="h-12 w-12 bg-white flex items-center justify-center rounded">
                <FaLinkedinIn className="text-[#0d6079]" />
              </a>
            </div>
          </div>
          {/* Second Column */}
          <div className=" ">
            <h4 className="text-gray-200 text-lg font-semibold mb-4">Company</h4>
            <ul>
              <li className="mb-2">
                <a href="/about" className="text-gray-400 hover:text-white transition">
                  About
                </a>
              </li>
              <li className="mb-2">
                <a href="/contactus" className="text-gray-400 hover:text-white transition">
                  Contact us
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="text-gray-400 hover:text-white transition">
                  Support
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="text-gray-400 hover:text-white transition">
                  Careers
                </a>
              </li>
            </ul>
          </div>
          {/* Third Column */}
          <div className=" ">
            <h4 className="text-gray-200 text-lg font-semibold mb-4">Quick Link</h4>
            <ul>
              <li className="mb-2">
                <a href="#" className="text-gray-400 hover:text-white transition">
                  Share Location
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="text-gray-400 hover:text-white transition">
                  Orders Tracking
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="text-gray-400 hover:text-white transition">
                  Size Guide
                </a>
              </li>
              <li className="mb-2">
                <a href="/faq" className="text-gray-400 hover:text-white transition">
                  FAQs
                </a>
              </li>
            </ul>
          </div>
          {/* Fourth Column */}
          <div className=" ">
            <h4 className="text-gray-200 text-lg font-semibold mb-4">Legal</h4>
            <ul>
              <li className="mb-2">
                <a href="#" className="text-gray-400 hover:text-white transition">
                  Terms & conditions
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white transition">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
