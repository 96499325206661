import React from "react";
import TestimonialSlider from "../../Common/TestimonialSlider";
import Button from '../../Common/Button'
export const Testimonial = () => {
  return (
    <div className=" text-black pb-20 pt-8">
      <div className="container mx-auto flex flex-col lg:flex-row items-center">
        {/* Left Content */}
        <div className="grid grid-cols-12">
          <div className=" col-span-12 lg:col-span-6 px-8">
            <h4 className="text-sm sm:text-base lg:text-lg text-custom-orenge pb-4">Clients</h4>

            <h2 className='font-bold text-custom-blue lg:text-[50px] text-[40px] md:text-[45px]'>
              Happy with   <span className="hidden sm:inline"> <br /> </span>
              Customers & Clients
            </h2>

            <p className="mb-6">
              If you need any industrial solution, we are available for you. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <a href="/contactus"> <Button
              type='button'
              className="border-custom-red border  hover:border-custom-red   mt-3 xl:mt-0 lg:mt-0 sm:mt-3 md:mt-3 hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
              text=" Contact Us"
            /></a>


          </div>
          {/* Right Slider */}
          <div className=" col-span-12  lg:col-span-6  mt-8 lg:mt-0 bg-custom-blue">
            <TestimonialSlider />
          </div>
        </div>
       
      </div>
    </div>
  );
};
