import React from 'react'
import Logo from "../../assets/images/logo/LOGO.png"
export const HeaderLogo = () => {
    return (
        <>

            <img src={Logo} alt="" className="fixed top-[50px] left-10 lg:left-20 md:left-20" />

        </>
    )
}
