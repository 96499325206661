import React from 'react'
import GoogleImg from '../../../assets/images/home/google.png'
import PlayStoreImg from '../../../assets/images/home/playstore.png'
import MobileImg from '../../../assets/images/home/mobile.png'
import Dots from '../../../assets/images/home/dots.png'
const AppDownload = () => {
    return (
        <>
            <section className="py-10">
                <div className="container m-auto p-4">


                    <div className="grid grid-cols-12 xl:gap-6 lg:gap-6 items-center pt-4 "  >

                        <div className='col-span-12 md:col-span-6 lg:col-span-6 m-auto  '>
                            <div>
                                <h2 className="font-bold text-gray-950 pb-3 lg:text-[50px] text-[40px] md:text-[45px] font-robbto">DOWNLOAD APP &
                                    <span class="hidden sm:inline"> <br /> </span>
                                    GET THE <span className='text-custom-red'>CASH FUSE</span> !</h2>
                                <p className='text-xl text-gray-400'>Get 30% off for first transaction using
                                    Rondovision <span class="hidden sm:inline"> <br /> </span> mobile app for now.</p>
                            </div>
                            <div className='flex gap-3 pt-4'>
                                <img src={GoogleImg} alt="GoogleImg" className='' />
                                <img src={PlayStoreImg} alt="PlayStoreImg" className='' />
                            </div>
                        </div>
                        <div className='col-span-12 w-full md:col-span-6 lg:col-span-6  mt-7 lg:mt-0' style={{
                            background: `url(${Dots})
                      `, backgroundRepeat: 'no-repeat',
                        }}>
                            <img src={MobileImg} alt="leftFashion" className='' />
                        </div>
                    </div>

                </div>
            </section>

        </>
    )
}


export default AppDownload;