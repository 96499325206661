import React from "react";

const CashFuseCard = ({ shopName, offerAmount,  backgroundImage, offerName }) => {
  return (
    <div
      className="bg-cover rounded-lg p-6 text-white font-sans relative w-full h-full flex flex-col justify-between"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Shop Name - Positioned top right */}
      <div className="absolute top-3 right-3 text-md font-bold   bg-opacity-50 p-1 rounded">{shopName}</div>

      <div className="flex items-center justify-between">
        <div className="ps-3">
          {/* Flat Text */}
          <div className="text-sm font-bold">FLAT</div>

          {/* Offer Amount */}
          <div className="text-5xl font-bold">{offerAmount}</div>

          {/* Offer Text */}
          <div className="text-lg mb-4">FushFuse Off</div>
        </div>

        {/* Amount Text */}
        {/* <div
          className="bg-white text-black px-3 py-1 rounded text-sm text-center items-center inline-block relative sm:right-[46%] xl:right-[46%] lg:right-[20%] md:right-[20%] right-[10%]  offer_name    leading-tight"
          dangerouslySetInnerHTML={{ __html: offerName }}
        /> */}
        <div className="  offer_name  absolute top-[45%]  left-[50%]" >
          <h2 className="bg-white leading-tight text-black px-3 py-1 rounded text-sm  text-center items-center inline-block ">{offerName}</h2>
        </div>
      </div>

      {/* CTA Button */}
      <button className="bg-orange-600 text-white px-6 py-2 rounded-full text-sm hover:bg-orange-500 absolute bottom-3 right-3">
        Shop Now
      </button>
    </div>
  );
};

export default CashFuseCard;
