import React from "react";

const ProductCard = ({ productData }) => {
  return (
    <>
      {productData &&
        productData?.map((items, index) => {
          return (
            <>
              
              <div className=" lg:w-80 h-74 w-72 sm: rounded-3xl shadow-[rgba(0, 0, 0, 0.2)]   shadow-lg p-4 bg-[#fbfbfb]">
                <div className=" flex justify-end font-[inter] ">
                  <p className="w-40 h-10 bg-[#025D89] text-white rounded-tr-full rounded-bl-full flex justify-center items-center ">15% Cashfuse off</p>
                </div>
                <div className="flex justify-center items-center pt-2">
                  <img className="w-40 h-32" src={items.image} alt="Food" />
                </div>
                <div className="flex  justify-center items-center text-center ">
                  <span className="font-medium font-[inter] text-xl">
                    {items.hadding}
                  </span>
                  <span className="font-normal font-[Poppins] text-sm text-[#F02525] pt-1 ps-10 pe-3 ">
                    Open at {items.time}
                  </span>
                  <img src="./image/timeWatch.svg" alt="order bike icon" />
                </div>
                <p className="font-medium font-[inter] text-sm pt-1 ps-7 text-[#B8BBC6]">
                  {items.description}
                </p>

                <div className="flex justify-center items-center text-center pt-1 ">
                  <span className="text-[#FFCC00] text-lg">&#9733;</span>
                  <span className="font-medium font-[inter] text-xl ps-2">
                    {items.review}
                  </span>
                  <span className="font-medium font-[inter] ps-2  text-[#B8BBC6]">
                    ({items.ratings} ratings)
                  </span>

                  <span className="font-normal font-[Poppins] text-[#353333]  ps-10 pe-3">
                    {items.kilometer} km
                  </span>
                  <img src="./image/orderbike.svg" alt="order bike icon" />
                </div>
              </div>
            </>
          );
        })}
    </>
  );
};

export default ProductCard;



