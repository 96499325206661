// src/Components/Sidebar/Sidebar.jsx
import React, { useState } from "react";
import "../../assets/styles/Navbar.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocation, matchPath } from "react-router-dom";
import {
  LuLayoutDashboard,
  LuUserCircle,
  LuCircleDollarSign,
  LuRepeat,
  LuWallet,
  LuHelpCircle,
  LuLogOut,
  LuClipboardSignature,
} from "react-icons/lu";
import { CiMenuFries, CiSettings } from "react-icons/ci";
import { BiSolidOffer, BiWindows } from "react-icons/bi";

import { ClearLocalData } from "../../Networking/localStorageHelper";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const activeClass = "bg-white/50 !text-white";
  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    let windowConfirm = window.confirm("Are you Sure you want to logout ");
    if (!windowConfirm) {
      return;
    }
    ClearLocalData("");
    navigate("/signin");
  };
  const location = useLocation();
  const isCashFuseActive = matchPath({ path: "/cashFuseOffer" }, location.pathname);
  const isAddOfferActive = matchPath({ path: "/addOffer" }, location.pathname);
  const isActive = isCashFuseActive || isAddOfferActive;

  return (
    <>
      <div
        id="mySidebar"
        className={`sidebar ${isOpen ? "" : "closed"} ${!isOpen && window.innerWidth <= 768 ? "open" : ""}`}
      >
        <div className="sidebar-header">
          <button className="toggle-btn" onClick={toggleNav}>
            <CiMenuFries />
          </button>
        </div>

        <NavLink to="/dashboard" className={({ isActive }) => (isActive ? activeClass : "")}>
          <LuLayoutDashboard /> <span className="ps-3"> Dashboard</span>
        </NavLink>
        <NavLink to="/MyProfile" className={({ isActive }) => (isActive ? activeClass : "")}>
          <LuUserCircle /> <span className="ps-3"> My Profile</span>
        </NavLink>
        <NavLink to="/kycComponents" className={({ isActive }) => (isActive ? activeClass : "")}>
          <LuClipboardSignature /> <span className="ps-3"> KYC</span>
        </NavLink>
        <NavLink to="/cashfuseCustomer" className={({ isActive }) => (isActive ? activeClass : "")}>
          <LuCircleDollarSign /> <span className="ps-3"> CashFuse Customer</span>
        </NavLink>
        <NavLink to="/myTransection" className={({ isActive }) => (isActive ? activeClass : "")}>
          <LuRepeat /> <span className="ps-3"> My Transection</span>
        </NavLink>
        <NavLink to="/wallet" className={({ isActive }) => (isActive ? activeClass : "")}>
          <LuWallet /> <span className="ps-3"> Wallet</span>
        </NavLink>

        {/* <NavLink to="/cashFuseOffer" className={({ isActive }) => (isActive ? activeClass : "")}>
          <BiSolidOffer /> <span className="ps-3"> Cash Fuse Offer</span>
        </NavLink> */}
        <NavLink to="/cashFuseOffer" className={isActive ? `${activeClass} flex items-center` : "flex items-center"}>
          <BiSolidOffer />
          <span className="ps-3">CashFuse Offer</span>
        </NavLink>
        <NavLink to="/createbanner" className={({ isActive }) => (isActive ? activeClass : "")}>
          <BiSolidOffer />
          <span className="ps-3"> Create Banner</span>
        </NavLink>
        <h3 className="ps-1 xl:ps-3 lg:ps-3 md:ps-3 sm:ps-1 sm text-white pt-3 pb-3">OTHERS</h3>

        <NavLink to="/conditions" className={({ isActive }) => (isActive ? activeClass : "")}>
          <BiWindows /> <span className="ps-3"> Terms & Conditions</span>
        </NavLink>
        <NavLink to="/help" className={({ isActive }) => (isActive ? activeClass : "")}>
          <LuHelpCircle />
          <span className="ps-3"> Help</span>
        </NavLink>
        <NavLink onClick={handleLogout}>
          <LuLogOut /> <span className="ps-3"> Logout</span>
        </NavLink>
      </div>
    </>
  );
};

export default Sidebar;
