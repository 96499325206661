import React from "react";

const CardBanner = ({
  tagline,
  title,
  cashBackReward,
  bannerProducImage,
  buttonText,
  onButtonClick,
  backgroundImage,
}) => {
  return (
    <div
      className="bg-cover bg-center rounded-lg p-6 text-white font-sans relative w-full h-full  flex flex-col md:flex-row justify-between"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Text Section */}
      <div className="p-6 flex-1">
        <h3 className="text-white font-bold text-lg mb-2">{tagline}</h3>
        <div className="text-white font-extrabold text-3xl mb-3">{title}</div>
        <div className="text-lg mb-3">Up to {cashBackReward} Cashfuse Off</div>
        <button
          onClick={onButtonClick}
          className="bg-white text-red-500 font-semibold py-2 px-4 rounded shadow-md hover:bg-gray-200"
        >
          {buttonText}
        </button>
      </div>

      {/* Image Section */}
      <div className="flex-shrink-0 w-auto h-auto max-w-full max-h-full">
        <img src={bannerProducImage} alt={title} className="w-full h-full object-contain" />
        {/* <img src={bannerProducImage} alt="Banner Product" className="w-full sm:w-1/2 object-cover" /> */}
      </div>
    </div>
  );
};

export default CardBanner;
