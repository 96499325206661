 
import React from "react";

const CategoryCard = ({ categoriesData }) => {
  return (
    


    
    <div className="grid grid-cols-2 pt-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
      {categoriesData &&
        categoriesData.map((items, index) => (
          <div key={index} className="text-center">
            <div className="rounded-full m-auto border-2 hover:border-[#008ECC] cursor-pointer w-32 h-32 shadow-inner flex items-center justify-center bg-[#F5F5F5]">
              <img className="h-20 w-28 " src={items.icons} alt="CategoryImage" />
            </div>
            <p className="pt-2 font-normal">{items.name}</p>
          </div>
        ))}
    </div>
    
  );
};

export default CategoryCard;


