import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Import Link and useLocation from react-router-dom
import Logo from "../../assets/images/logo/LOGO.png";
import Button from "../Common/Button";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation(); // Hook to get the current location

  const navItems = [
    { name: "Home", href: "/" },
    { name: "About", href: "/about" },
    { name: "Offer", href: "/offer" },
    { name: "Contact Us", href: "/contactus" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`bg-white shadow-md ${isSticky ? "sticky top-0 z-50" : ""}`}>
      <nav className="bg-white w-full z-20 top-0 start-0 border-b border-gray-200">
        <div className="container mx-auto p-4 flex justify-between items-center">
          <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={Logo} alt="logo" />
          </Link>

          <div className="flex items-center space-x-3 md:space-x-6 relative">
            <div className="hidden md:flex space-x-6">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`block py-2 px-4 rounded ${
                    location.pathname === item.href
                      ? "text-custom-red font-bold"
                      : "text-gray-800 hover:text-custom-red"
                  }`}
                  aria-current={location.pathname === item.href ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <Button
              type="submit"
              onClick={() => setOpen(!open)}
              className="bg-custom-red text-white font-bold py-2 px-4 rounded hover:bg-red-600 transition duration-300"
              text="Sign In / Up"
            />

            {open && (
              <div className="absolute right-4 mt-36 z-10 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                <div>
                  <Link to="/signin" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200">
                    Sign In
                  </Link>
                  <Link to="/signup" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200">
                    Sign Up
                  </Link>
                </div>
              </div>
            )}

            <button
              onClick={() => setMenuOpen(!menuOpen)}
              type="button"
              className="md:hidden p-2 w-10 h-10 flex items-center justify-center text-gray-600 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-sticky"
              aria-expanded={menuOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
        </div>

        <div
          className={`md:hidden ${menuOpen ? "block" : "hidden"} bg-white border-t border-gray-200`}
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 space-y-2">
            {navItems.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.href}
                  className={`block py-2 px-3 rounded ${
                    location.pathname === item.href ? "text-custom-red font-bold" : "text-gray-900 hover:bg-gray-100"
                  }`}
                  aria-current={location.pathname === item.href ? "page" : undefined}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
