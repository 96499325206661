
const endpoints = {
    SIGNUP: "merchant/sign_up",
    SIGNIN: "merchant/login",
    FORGOTPASSWORD: "merchant/forgot_password",
    OTPVERIFICATION: "merchant/verify_otp",
    RESENDOTP: "merchant/resendOtp",
    RESETPASSWORD: "merchant/reset_Password",
    CASHFUSECUSTOMER: "merchant/casefuse_customer",
    GETPROFILE: "merchant/get_profile",
    UPDATEPROFILE: "merchant/update_profile",
    TRANSECTION: "merchant/payment_history",
    CATEGORYLIST: "merchant/category_list",
    CONTACTUS: "user/contact_us",
    ADDOFFER: "merchant/add_offer",
    GETOFFER: "merchant/get_offer",
    TERMSCONDATION: "merchant/add_terms",
    GETTREAM: "merchant/get_terms",
    AddFAQ: "merchant/addFaq",
    GETFAQ: "merchant/getFaq",
    ADDOFFERUPDATE: "merchant/update_offer",
    ADDBANNER: "/merchant/add_banner",
    GETBANNER: "/merchant/get_banner",
    ADDBANNERUODATE: "/merchant/update_banner",
    // GETBANNER:"/merchant/get_banner",
    GETTOPOFFER: "/user/homepage?lat=26.487890&long=74.655850",
    DASHBOARD: "/merchant/dashBoard",
    WALLET: "/merchant/withdraw_history",
    WITHDRAWAMOUNT: "/merchant/withdrawAmount",



};

export { endpoints };
