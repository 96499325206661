import React, { useState } from 'react';
import { State, City } from 'country-state-city';
import * as Yup from 'yup';
import useNetworkServices from '../../../Networking/baseAPIManager';
import { showToast } from '../../Common/Toastify';


import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
  const { ContactUsData } = useNetworkServices();
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    city: "",
    state: "",
    subject: "",
    message: "",
    sendUpdate: false,
  });

  const [errors, setErrors] = useState({});
  const [states, setStates] = useState(State.getStatesOfCountry('IN'));
  const [cities, setCities] = useState([]);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    mobile: Yup.string().matches(/^[6-9]\d{9}$/, 'Invalid mobile number').required('Mobile number is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required'),
  });

  const validate = () => {
    try {
      validationSchema.validateSync(formData, { abortEarly: false });
      return {};
    } catch (error) {
      const formErrors = {};
      error.inner.forEach(err => {
        formErrors[err.path] = err.message;
      });
      return formErrors;
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleStateChange = (e) => {
    const selectedStateCode = e.target.value;
    const selectedState = states.find(state => state.isoCode === selectedStateCode);
    setFormData({
      ...formData,
      state: selectedState.name,
    });
    setCities(City.getCitiesOfState('IN', selectedStateCode));
  };

  const handleCityChange = (e) => {
    setFormData({
      ...formData,
      city: e.target.value,
    });
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        // Simulate form submission
        console.log(formData, "formdata");
        const response = await ContactUsData(formData);
        console.log(response?.data, "ResponseData");


        showToast('Form submitted successfully!', 'success');
        console.log(showToast, "showToast")
        // Reset form
        setFormData({
          fullName: "",
          email: "",
          mobile: "",
          city: "",
          state: "",
          subject: "",
          message: "",
          sendUpdate: false,
        });
        setCities([]);
        setTimeout(() => {
          navigate('/')

        }, 3000)
      } catch (error) {
        console.error('Form submission error:', error);
        showToast('Form submission failed. Please try again.', 'error');
      }
    }
  };



  return (
    <div className="w-full max-w-6xl mx-auto bg-white border border-[#F1F1F1] shadow-[0px 4px 10px rgba(13, 16, 45, 0.07)] p-10 rounded-lg">
      {/* Centered Header and Paragraph */}
      <div className="text-center mb-8 font-[inter]">
        <h1 className="text-[#1C244B] text-4xl font-semibold mb-4">Get in touch with us</h1>
        <p className="text-gray-600 text-lg">Get a Quote Immediately Upon Form Submission</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* First Column: Image */}
        <div className="md:col-span-1 flex items-start justify-start">
          <img
            src="./image/contactus.png"
            alt="Contact Us"
          />
        </div>

        {/* Second Column: Contact Us Form */}
        <div className="md:col-span-2">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
              <div>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-[#C5CAD1] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="fullName"
                  name="fullName"
                  type="text"
                  placeholder="Name"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  required
                />
                {errors.fullName && <p className="text-red-500 text-xs mt-1">{errors.fullName}</p>}
              </div>

              <div>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-[#C5CAD1] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
              </div>

              <div>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-[#C5CAD1] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="mobile"
                  name="mobile"
                  type="tel"
                  placeholder="Phone Number"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  required
                />
                {errors.mobile && <p className="text-red-500 text-xs mt-1">{errors.mobile}</p>}
              </div>

              <div>
                <select
                  className="block appearance-none w-full bg-white border border-[#C5CAD1] text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="state"
                  name="state"
                  value={states.find(state => state.name === formData.state)?.isoCode || ""}
                  onChange={handleStateChange}
                  required
                >
                  <option value="" disabled>Select State</option>
                  {states.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors.state && <p className="text-red-500 text-xs mt-1">{errors.state}</p>}
              </div>

              <div>
                <select
                  className="block appearance-none w-full bg-white border border-[#C5CAD1] text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleCityChange}
                  disabled={!formData.state}
                  required
                >
                  <option value="" disabled>Select City</option>
                  {cities.map((city) => (
                    <option key={city.name} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
                {errors.city && <p className="text-red-500 text-xs mt-1">{errors.city}</p>}
              </div>

              <div>
                <select
                  className="block appearance-none w-full bg-white border border-[#C5CAD1] text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>Select Subject</option>
                  <option>General Inquiry</option>
                  <option>Support</option>
                  <option>Feedback</option>
                </select>
                {errors.subject && <p className="text-red-500 text-xs mt-1">{errors.subject}</p>}
              </div>
            </div>

            <div className="mb-6">
              <textarea
                className="appearance-none block w-full bg-white text-gray-700 border border-[#C5CAD1] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="message"
                name="message"
                rows="4"
                placeholder="Message"
                value={formData.message}
                onChange={handleInputChange}
                required
              />
              {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
            </div>

            <div className="flex items-center mb-6">
              <input
                type="checkbox"
                id="sendUpdate"
                name="sendUpdate"
                checked={formData.sendUpdate}
                onChange={handleInputChange}
                className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2"
              />
              <label htmlFor="sendUpdate" className="text-gray-700 text-sm">
                I agree to receive information, offers, and updates from Cashfuse*
              </label>
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-[#E73921] text-white font-bold py-2 px-8 rounded-lg
                hover:bg-[#C72D19] transition-colors duration-300"
              >
                Submit
              </button>



            </div>
          </form>


        </div>
      </div>
    </div>
  );
};

export default ContactUs;
