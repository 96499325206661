import React, { useState } from "react";
import BG1 from "../../../assets/images/AboutUs/bg.png";
import BG2 from "../../../assets/images/AboutUs/bg1.png";
import Left1 from "../../../assets/images/Offers/watch.png";
import Left2 from "../../../assets/images/Offers/mobile.png";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import CategoryCard from "../../Common/Cards/CategoryCard";
import ProductCard from "../../Common/Cards/ProductCard";
import Mobimg from '../../../assets/images/Offers/milogo.svg'
const OfferPage = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const categoriesData = [
    { name: "Food", icons: "./image/food.svg" },
    { name: "Pharmacy", icons: "./image/Pharmacy.svg" },
    { name: "Fruits & Veg", icons: "./image/Fruits_Veg.svg" },
    { name: "Electronic", icons: "./image/Electronic.svg" },
    { name: "Fashion & Wear", icons: "./image/Fashion_Wear.svg" },
    { name: "Beauty", icons: "./image/Beauty.svg" },
  ]

  const productData = [
    { image: "./image/food.svg", hadding: "Corn Burger", time: "12 noon", description: "Beyti Restaurant, Taksim", review: "4.8", ratings: "233", kilometer: "2.5", },
    { image: "./image/sandwich.svg", hadding: "Breck fast hub", time: "12 noon", description: "Beyti Restaurant, Taksim", review: "4.5", ratings: "158", kilometer: "2.5", },
    { image: "./image/ice-cream.svg", hadding: "Cold Ice hut", time: "12 noon", description: "Beyti Restaurant, Taksim", review: "4.6", ratings: "188", kilometer: "2.5", },
    { image: "./image/orangejuice.svg", hadding: "Gulab Raswala", time: "12 noon", description: "Beyti Restaurant, Taksim", review: "4.1", ratings: "98", kilometer: "2.5", },
    { image: "./image/food.svg", hadding: "Corn Burger", time: "12 noon", description: "Beyti Restaurant, Taksim", review: "4.8", ratings: "233", kilometer: "2.5", },
    { image: "./image/sandwich.svg", hadding: "Breck fast hub", time: "12 noon", description: "Beyti Restaurant, Taksim", review: "4.5", ratings: "158", kilometer: "2.5", },
    { image: "./image/ice-cream.svg", hadding: "Cold Ice hut", time: "12 noon", description: "Beyti Restaurant, Taksim", review: "4.6", ratings: "188", kilometer: "2.5", },
    { image: "./image/orangejuice.svg", hadding: "Gulab Raswala", time: "12 noon", description: "Beyti Restaurant, Taksim", review: "4.1", ratings: "98", kilometer: "2.5", },
  ];

  const images = [
    {
      src: BG1,
      leftImg: Left1,
      dealhadding: "Best Deal Online on smart watches",
      heading: "SMART WEARABLE.",
      description: "Up to 25 Cashfuse Off",
      color: "rgba(0, 142, 204, 1)",

    },
    {
      src: BG2,
      leftImg: Left2,
      dealhadding: "Best Deal on XIAOMI 11i",
      heading: "Mobile Phone",
      description: "Up to 25 Cashfuse Off",
      icon: Mobimg,
      color: "rgba(231, 57, 33, 1)",
    },

  ];

  const handleNext = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? images.length - 1 : prevImage - 1
    );
  };

  return (
    <div className="container mx-auto px-4 relative py-20">
      {/* Background Image Section */}
      <div
        className="relative justify-around bg-no-repeat bg-cover rounded-lg text-white grid grid-cols-1 lg:grid-cols-2 items-center"
        style={{
          backgroundImage: `url(${images[currentImage].src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",

        }}
      >

        <div className=" p-8 lg:p-12 lg:ms-10  sm:p-10">
          <h5 className="font-['Roboto'] text-sm sm:text-base lg:text-lg text-gray-100">{images[currentImage].dealhadding}</h5>
          <h2 className="font-['Roboto Slab'] flex   font-bold text-white lg:text-[50px] text-[40px] md:text-[45px]">
            <img src={images[currentImage].icon} alt="" className={`${images[currentImage].icon ? "pe-3" : ""}`} />
            {images[currentImage].heading}
          </h2>
          <p className="font-['Roboto'] text-sm sm:text-base lg:text-lg text-gray-100 ">
            {images[currentImage].description}
          </p>
        </div>
        <div className="hidden lg:block m-auto">
          <img src={images[currentImage].leftImg} alt="" className="p-10 w-72  object-cover" />
        </div>



        <button
          className="rounded-full border-8 border-white  flex items-center justify-center absolute left-0 transform -translate-x-1/2 top-1/2 -translate-y-1/2"
          style={{
            backgroundColor: "rgba(243, 249, 251, 1)",
            color: images[currentImage].color,
           
          }}
          onClick={handlePrev}
        >
          <MdKeyboardArrowLeft size={24} />
        </button>
        <button
          className="rounded-full border-8 border-white  flex items-center justify-center absolute right-0 transform translate-x-1/2 top-1/2 -translate-y-1/2"
          style={{
            backgroundColor: "rgba(243, 249, 251, 1)",
            color: images[currentImage].color,
            
          }}
          onClick={handleNext}
        >
          <MdKeyboardArrowRight size={24} />
        </button>
      </div>

      {/* About Us Section */}
      <section className="font-[roboto] mt-16 ">
        <div className="flex justify-between ">
          <span className="text-[#025D89] font-semibold text-[35px]">Popular Casfuse Offer</span>
          <a href="#" className="font-medium pt-5">View All <span className="text-[#008ECC]">&#11166;</span></a>
        </div>



        <CategoryCard categoriesData={categoriesData} />


      </section>

      {/* Partners Section */}
      <section className="font-[roboto] py-12 ">
        <h3 className="text-[#025D89] font-semibold text-[35px] pb-12">
          Exclusive: Save at Your Fave Outlets
        </h3>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12">
          <ProductCard productData={productData} />
        </div>
      </section>
    </div>
  );
}

export default OfferPage;
