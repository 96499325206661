import React, { useEffect, useState, useRef } from "react";
import SubHeaderMenu from "./Sidebar/SubHeaderMenu";
import Button from "./Common/Button";
import { useLocation } from "react-router-dom";
import Tabs from "./MyProfileComponents/Tabs";
import MyBusiness from "./MyProfileComponents/MyBusiness";
import BankDetails from "./MyProfileComponents/BankDetails";
import Document from "./MyProfileComponents/Document";
import PersonalInfo from "./MyProfileComponents/PersonalInfo";
import UserDefaultImage from "../assets/images/UserDefultImage.png";
import useNetworkServices from "../Networking/baseAPIManager";

const MyProfile = ({ onProfileUpdate }) => {
  const [imgData, setImgData] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [shopName, setShopname] = useState(null);
  const ImageInputRef = useRef();

  const location = useLocation();
  const { GetProfile } = useNetworkServices();
  const BASE_URL = "https://liveuat.com:5438";

  const fetchProfileData = async () => {
    try {
      const response = await GetProfile();
      console.log(response?.data?.data?.profileImage, "response111");

      setProfileData(response.data.data);
      setShopname(response.data.data.businessDetils);
      setImgData(response.data.data.profileImage);
      onProfileUpdate(response.data.data);
      localStorage.setItem("profileImage", response.data.data.profileImage);
      localStorage.setItem("profileName", `${response.data.data.firstName} ${response.data.data.lastName}`);
      localStorage.setItem("shopName", response.data.data.businessDetils.shopName);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, [GetProfile]);

  const handleImageChange = (file) => {
    setImgData(file);
    onProfileUpdate({ ...profileData, profileImage: file });
  };

  const handleProfileUpdate = () => {
    fetchProfileData();
  };

  const userImage = imgData
    ? typeof imgData === "object"
      ? URL.createObjectURL(imgData)
      : `${BASE_URL}/${imgData}`
    : UserDefaultImage;

  const tabs = [
    {
      title: "Personal Info",
      content: (
        <PersonalInfo
          profileData={profileData}
          onImageChange={handleImageChange}
          profileImage={imgData}
          setImgData={setImgData}
          onProfileUpdate={handleProfileUpdate}
        />
      ),
    },
    {
      title: "My Business",
      content: (
        <MyBusiness
          profileData={profileData}
          onImageChange={handleImageChange}
          profileImage={imgData}
          setImgData={setImgData}
          onProfileUpdate={handleProfileUpdate}
        />
      ),
    },
    {
      title: "Bank Details",
      content: (
        <BankDetails
          profileData={profileData}
          onImageChange={handleImageChange}
          profileImage={imgData}
          setImgData={setImgData}
          onProfileUpdate={handleProfileUpdate}
        />
      ),
    },
    {
      title: "Document",
      content: (
        <Document
          profileData={profileData}
          onImageChange={handleImageChange}
          profileImage={imgData}
          setImgData={setImgData}
          onProfileUpdate={handleProfileUpdate}
        />
      ),
    },
  ];
  console.log(userImage, "userImage");

  return (
    <div id="main" className="p-4">
      <h1 className="font-bold text-2xl text-slate-900 pb-5">
        <SubHeaderMenu location={location} />
      </h1>

      <div className="p-6 xl:flex justify-between lg:flex md:flex sm:flex">
        <div className="xl:flex lg:flex md:flex sm:flex  items-center">
          <div className="relative w-16 h-16 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
            <img src={userImage} alt="Profile" />
          </div>
          <div className="ps-4">
            <h3 className="text-xl font-bold text-gray-900">
              {profileData?.firstName} {profileData?.lastName}
            </h3>
            <h6 className="text-md font-semibold text-gray-600 pt-1">{shopName?.shopName}</h6>
            <p className="text-sm text-gray-400 pt-1">{profileData?.city}</p>
          </div>
        </div>

        <div className="pt-5 xl:pt-0 lg:pt-0">
          <input
            type="file"
            ref={ImageInputRef}
            onChange={(e) => handleImageChange(e.target.files[0])}
            style={{ display: "none" }}
          />
          <Button
            type="button"
            className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
            text="Upload Photo"
            onClick={() => {
              ImageInputRef.current.click();
            }}
          />
        </div>
      </div>

      <div>
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
};

export default MyProfile;
