import React from "react";

export const Table = ({ columns, data }) => {
  const validData = Array.isArray(data) ? data : [];

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className="py-3 px-4 text-left text-xs sm:text-sm font-bold text-custom-black whitespace-nowrap"
                // className="py-3 px-4 text-left text-sm font-bold text-custom-black"
              >
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {validData.length > 0 ? (
            validData.map((row, rowIndex) => (
              <tr key={rowIndex} className={rowIndex % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                {columns.map((column, colIndex) => (
                  <td key={colIndex} className="py-3 px-4 text-xs sm:text-sm whitespace-nowrap">
                    {column.customBtn ? column.customBtn(row) : row[column.accessor] || "N/A"}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="text-center py-4">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
