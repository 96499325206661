  import React, { useEffect, useState } from 'react';
  import Button from '../Common/Button';
  import useNetworkServices from '../../Networking/baseAPIManager';
  import { useNavigate } from 'react-router-dom';

const BankDetails = ({ onImageChange, profileImage, profileData, onProfileUpdate }) => {
    const { UpdateProfile } = useNetworkServices();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
      bankName: '',
      accountHolderName: '',
      accountNumber: '',
      ifscCode: '',
      image: profileImage,
    });

  
  const validBankNames = [
    'State Bank of India',
    'HDFC Bank',
    'ICICI Bank',
    'Axis Bank',
    'Kotak Mahindra Bank',
    'Punjab National Bank',
    'Yes Bank',
    'Bank of Baroda',
    'Union Bank of India',
    'DBS Bank',
    'Standard Chartered Bank',
    'Citi Bank',
    'HSBC Bank',
    'RBL Bank'

  ];
  const bankIfscPatterns = {
    'State Bank of India': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'HDFC Bank': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'ICICI Bank': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'Axis Bank': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'Kotak Mahindra Bank': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'Punjab National Bank': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'Yes Bank': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'Bank of Baroda': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'Union Bank of India': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'DBS Bank': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'Standard Chartered Bank': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'Citi Bank': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'HSBC Bank': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'RBL Bank': /^[A-Z]{4}0[A-Z0-9]{6}$/

  };

    useEffect(() => {
      if (profileData) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          bankName: profileData?.bankDetails?.bankName || '',
          accountHolderName: profileData?. bankDetails?.accountHolderName || '',
          accountNumber: profileData?.bankDetails?.accountNumber || '',
          ifscCode: profileData?.bankDetails?.ifscCode || '',
          image: profileImage,
        }));
      }

    }, [profileData, profileImage]);


    const [error, setErrors] = useState({})
    const handleChange = (e) => {
      const { name, value, files } = e.target;


      if (name === 'accountNumber' && !/^\d*$/.test(value)) {
        return;
      }

      const newErrors = { ...error }; // Maintain previous errors

      const nameRegex = /^[a-zA-Z\s]+$/;

      // Bank Name validation
      if (name === 'bankName') {
        if (!value) {
          newErrors.bankName = 'Bank Name is required';
        } else if (!validBankNames.includes(value)) {
          newErrors.bankName = 'Please select a valid Bank Name';
        } else {
          delete newErrors.bankName;  // Only remove if valid
        }
      }
      // Account Holder Name validation
      else if (name === 'accountHolderName') {
        if (!value) {
          newErrors.accountHolderName = 'Account Holder Name is required';
        } else if (!nameRegex.test(value)) {
          newErrors.accountHolderName = 'Account Holder Name should contain only letters and spaces';
        } else {
          delete newErrors.accountHolderName; // Only remove if valid
        }
      }
      // Account Number validation
      else if (name === 'accountNumber') {
        if (!value) {
          newErrors.accountNumber = 'Account Number is required';
        } else if (!/^\d{9,18}$/.test(value)) {
          newErrors.accountNumber = 'Account Number must be between 9 and 18 digits';
        } else {
          delete newErrors.accountNumber; // Only remove if valid
        }
      }
      // IFSC Code validation
      else if (name === 'ifscCode') {
        const selectedBank = formData.bankName;
        if (!value) {
          newErrors.ifscCode = 'IFSC Code is required';
        } else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) {
          newErrors.ifscCode = 'Invalid IFSC Code';
        } else if (!selectedBank || !bankIfscPatterns[selectedBank]) {
          newErrors.ifscCode = 'Please select a valid bank before entering the IFSC Code';
        } else if (!bankIfscPatterns[selectedBank]) {
          newErrors.ifscCode = `Invalid IFSC Code for ${selectedBank}`
        } else {
          delete newErrors.ifscCode; // Only remove if valid
        }
      }



      setErrors(newErrors);


      setFormData({
        ...formData,
        [name]: files ? files[0] : value,
      });
      if (name === 'image' && files) {
        onImageChange(files[0]);
      }
    };

    const validateForm = () => {
      const newErrors = {};
      const nameRegex = /^[a-zA-Z\s]+$/;

      if (!formData.bankName) {
        newErrors.bankName = 'Bank Name is required';
      }

      if (!formData.accountHolderName) {
        newErrors.accountHolderName = 'Account Holder Name is required';
      } else if (!/^[a-zA-Z\s]+$/.test(formData.accountHolderName)) {
        newErrors.accountHolderName = 'Account Holder Name should contain only letters and spaces';
      }

      if (!formData.accountNumber) {
        newErrors.accountNumber = 'Account Number is required';
      } else if (!/^\d{9,18}$/.test(formData.accountNumber)) {
        newErrors.accountNumber = 'Account Number must be between 9 and 18 digits';
      }

      if (!formData.ifscCode) {
        newErrors.ifscCode = 'IFSC Code is required';
      } else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(formData.ifscCode)) {
        newErrors.ifscCode = 'Invalid IFSC Code';
      }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0
    }

    const handleFormSubmit = async (e) => {
      e.preventDefault();

      if (validateForm()) {
        const data = new FormData();
        Object.keys(formData).forEach((key) => {
          data.append(key, formData[key]);
        });

        try {
          const response = await UpdateProfile(data);
          if (response.data && response.data.photo) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              image: response.data.photo,
            }));
            onImageChange(response.data.photo);
          }
          console.log(response.data, 'ResponseData');
          onProfileUpdate();
          navigate('/MyProfile#document');
        } catch (error) {
          console.error('Update Profile error:', error);
        }
      }
    };

    const handleCancel = () => {
      setFormData({
        bankName: '',
        accountHolderName: '',
        accountNumber: '',
        ifscCode: '',
        profileImage: '',
      });
      navigate('/MyProfile');
    };



    const inputFields = [
      { name: 'bankName', type: 'select', label: 'Bank Name', placeholder: 'Bank Name', },
      { name: 'accountHolderName', type: 'tel', label: 'Account Holder Name', placeholder: 'Account Holder Name' },
      { name: 'accountNumber', type: 'tel', label: 'Account Number', placeholder: 'Account Number' },
      { name: 'ifscCode', type: 'text', label: 'IFSC Code', placeholder: 'IFSC Code' },
    ];

    return (
      <div className='pt-8'>
        <form className="space-y-4 md:space-y-6" onSubmit={handleFormSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
            {/* {inputFields.map((field) => (
              <div key={field.name}>
                <label htmlFor={field.name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
                  {field.label}
                </label>
                <input
                  type={field.type}
                  name={field.name}
                  id={field.name}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                  placeholder={field.placeholder}
                  value={formData[field.name]}
                  onChange={handleChange}
                />
                {error[field.name] && (
                  <div className='text-red-500 text-sm'>{error[field.name]}</div>
                )}
              </div>
            ))} */}

            {inputFields.map((field) => (
              <div key={field.name}>
                <label htmlFor={field.name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
                  {field.label}
                </label>
                {field.type === 'select' && field.name === 'bankName' ? (
                  <select
                    name={field.name}
                    id={field.name}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none"
                    value={formData[field.name]}
                    onChange={handleChange}
                  >
                    <option value="" disabled>Select Bank Name</option>
                    {validBankNames.map((bank) => (
                      <option key={bank} value={bank}>{bank}</option>
                    ))}
                  </select>
                ) : (
                  <input
                    type={field.type}
                    name={field.name}
                    id={field.name}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                    placeholder={field.placeholder}
                    value={formData[field.name]}
                    onChange={handleChange}
                  />
                )}
                {error[field.name] && (
                  <div className='text-red-500 text-sm'>{error[field.name]}</div>
                )}
              </div>
            ))}
          </div>

          <div className='xl:flex lg:flex flex justify-end gap-4 pt-5 xl:pt-0 lg:pt-0'>
            <Button
              type='button'
              className="border-custom-red border hover:border-custom-red w-full mt-3 xl:mt-0 lg:mt-0 sm:mt-3 md:mt-3 hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
              text="Cancel"
              onClick={handleCancel}
            />
            <Button
              type="submit"
              className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
              text="Save Changes"
            />
          </div>
        </form>
      </div>
    );
  };

  export default BankDetails;
